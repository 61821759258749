/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { Add, Close, NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Chip, Divider, Link, MenuItem, Select, Typography } from "@mui/material";
import { useRouter } from "next/router";
import GrandAccessModal from "../../../modal/GrandAccessModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "admin"
		? {
				backgroundColor: "#d5d5d7",
				color: "#000000",
			}
		: id === "super_admin"
			? {
					backgroundColor: "#FFD700",
				}
			: id === "property"
				? {
						backgroundColor: "#FFFF00",
					}
				: id === "service"
					? {
							backgroundColor: "rgb(30,144,255,0.6)",
						}
					: id === "kyc"
						? {
								backgroundColor: " #00FF00",
							}
						: id === "iam"
							? {
									backgroundColor: "rgb(220,20,60,0.8)",
								}
							: id === "user_management"
								? {
										backgroundColor: "#FF00FF",
									}
								: id === "analytics"
									? {
											backgroundColor: "#eb57be",
										}
									: id === "subscriptions"
										? {
												backgroundColor: "#bf7752",
											}
										: id === "report"
											? {
													backgroundColor: "#FFA500",
												}
											: id === "bug_report"
												? {
														backgroundColor: "#B5A6D5",
													}
												: id === "post"
													? {
															backgroundColor: "rgba(255,0,10,0,1)",
														}
													: id === "business_profiles"
														? {
																backgroundColor: "rgb(0,191,255)",
															}
														: id === "employees"
															? {
																	backgroundColor: "#a8e3b8",
																}
															: id === "activity_log"
																? {
																		backgroundColor: "#e2d3e3",
																	}
																: id === "beegru_points_transaction"
																	? {
																			backgroundColor: "#86a80d",
																		}
																	: id === "boost_pricing_manual_overrides"
																		? {
																				backgroundColor: "#d9f086",
																			}
																		: id === "cash_transaction"
																			? {
																					backgroundColor: "#ed725f",
																				}
																			: id === "search_logs"
																				? {
																						backgroundColor: "#72708a",
																					}
																				: id === "boost_pricing_manual_overrides"
																					? {
																							backgroundColor: "rgb(125, 155, 165)",
																						}
																					: id === "logs"
																						? {
																								backgroundColor: "rgb(216, 221, 147)",
																							}
																						: id === "latest-changes"
																							? {
																									backgroundColor: "rgb(231, 134, 77)",
																								}
																							: id === "translations"
																								? {
																										backgroundColor: "rgb(176, 206, 93)",
																									}
																								: id === "leads"
																									? {
																											backgroundColor: "rgb(224, 90, 66)",
																										}
																									: id === "boost"
																										? {
																												backgroundColor: "rgb(94, 18, 5)",
																											}
																										: id === "reviews"
																											? {
																													backgroundColor: "rgb(216, 183, 177)",
																												}
																											: id === "property_all"
																												? {
																														backgroundColor: "rgb(29, 153, 126)",
																													}
																												: id === "service_all"
																													? {
																															backgroundColor: "rgb(72, 101, 165)",
																														}
																													: {
																															backgroundColor: "#FFFFFF",
																														},
);

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const ButtonContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const IAMDatatable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a constant for router, let's use ref to get the current value of router

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the modal for loading

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	/*

  & Next, let's define a local state for the modal for access level

  */

	const [openAccessModal, setOpenAccessMoadal] = React.useState(false);

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					page: paginationModel.page,
				},
			},
			undefined,
			{
				shallow: true,
			},
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount || 0);
	}, [DashboardData]);

	/*

  & Next, let's define a function to close the access level modal

  */

	const handleClose = () => {
		setOpenAccessMoadal(false);
	};

	/*

  & Next, let's define a function to handle the change in access level

  */

	const handleChange = async (item: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "IAM-table/accesslevel", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				userId: cellValues.id,
				access_level: item.target.value,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					change: "true",
					page: paginationModel.page,
				},
			},
			undefined,
			{
				shallow: true,
			},
		);

		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to remove admin access

  */

	const removeAdminAccess = async (cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "IAM-table/revoke-user", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				userId: cellValues.id,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					change: "true",
				},
			},
			undefined,
			{
				shallow: true,
			},
		);

		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to delete access for rest of the access levels

  */

	const deleteAccess = async (item: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "IAM-table/delete-access-level", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				deleteAccess: item,
				userId: cellValues.id,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					change: "true",
					page: paginationModel.page,
				},
			},
			undefined,
			{
				shallow: true,
			},
		);
		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to handle the click on grant access button

  */

	const handleClickGrandAccess = () => {
		setOpenAccessMoadal(true);
	};

	/*

  & Next, let's define the data for the menu items

  */

	const menuItems = [
		{
			id: 1,
			label: "Property",
			value: "property",
		},
		{
			id: 2,
			label: "Service",
			value: "service",
		},
		{
			id: 3,
			label: "KYC",
			value: "kyc",
		},
		{
			id: 4,
			label: "IAM",
			value: "iam",
		},
		{
			id: 5,
			label: "User Management",
			value: "user_management",
		},
		{
			id: 6,
			label: "Admin",
			value: "admin",
		},
		{
			id: 7,
			label: "Analytics",
			value: "analytics",
		},
		{
			id: 8,
			label: "Subscriptions",
			value: "subscriptions",
		},
		{
			id: 9,
			label: "Report",
			value: "report",
		},
		{
			id: 10,
			label: "Bug Report",
			value: "bug_report",
		},
		{
			id: 11,
			label: "Business Profiles",
			value: "business_profiles",
		},
		{
			id: 12,
			label: "Post",
			value: "post",
		},
		{
			id: 13,
			label: "Employees",
			value: "employees",
		},
		{
			id: 14,
			label: "Activity Log",
			value: "activity_log",
		},
		{
			id: 15,
			label: "Beegru Points Transaction",
			value: "beegru_points_transaction",
		},
		{
			id: 16,
			label: "Boost Pricing Manual Overrides",
			value: "boost_pricing_manual_overrides",
		},
		{
			id: 17,
			label: "Cash Transaction",
			value: "cash_transaction",
		},
		{
			id: 18,
			label: "Search Logs",
			value: "search_logs",
		},
		{
			id: 19,
			label: "Logs",
			value: "logs",
		},
		{
			id: 20,
			label: "Latest Changes",
			value: "latest-changes",
		},
		{
			id: 21,
			label: "Translations",
			value: "translations",
		},
		{
			id: 22,
			label: "Leads",
			value: "leads",
		},
		{
			id: 23,
			label: "Boost",
			value: "boost",
		},
		{
			id: 24,
			label: "Reviews",
			value: "reviews",
		},
		{
			id: 25,
			label: "Property All",
			value: "property_all",
		},
		{
			id: 25,
			label: "Service All",
			value: "service_all",
		},
	];

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "userId",
			headerName: "User ID",
			width: 250,
		},
		{
			field: "username",
			headerName: "Username",
			width: 200,
			// renderCell: (cellValues: any) => {
			//   return (
			//     <Link
			//       href="/"
			//       sx={{
			//         textDecoration: "none",
			//       }}
			//     >
			//       {cellValues.value}
			//     </Link>
			//   );
			// },
		},
		{
			field: "phonenumber",
			headerName: "Phone Number",
			width: 200,
		},
		{
			field: "access_level",
			headerName: "Access Level",
			width: 300,
			// type: "singleSelect",
			valueOptions: menuItems,
			editable: true,
			valueFormatter: (params: any) => {
				if (!params) {
					return "";
				}
				return params.value === "property"
					? "Property"
					: params.value === "service"
						? "Service"
						: params.value === "kyc"
							? "KYC"
							: params.value === "iam"
								? "IAM"
								: params.value === "user_management"
									? "User Management"
									: params.value === "analytics"
										? "Analytics"
										: params.value === "subscriptions"
											? "Subscriptions"
											: params.value === "report"
												? "Report"
												: params.value === "bug_report"
													? "Bug Report"
													: params.value === "business_profiles"
														? "Business Profiles"
														: params.value === "post"
															? "Post"
															: params.value === "employees"
																? "Employees"
																: params.value === "activity_log"
																	? "Activity Log"
																	: params.value === "beegru_points_transaction"
																		? "Beegru Points Transaction"
																		: params.value === "boost_pricing_manual_overrides"
																			? "Manual Points Transaction"
																			: params.value === "cash_transaction"
																				? "Cash Transaction"
																				: params.value === "search_logs"
																					? "Search Logs"
																					: params.value === "logs"
																						? "Logs"
																						: params.value === "latest-changes"
																							? "Latest Changes"
																							: params.value === "translations"
																								? "Translations"
																								: params.value === "leads"
																									? "Leads"
																									: params.value === "boost"
																										? "Boost"
																										: params.value === "reviews"
																											? "Reviews"
																											: params.value === "property_all"
																												? "Property All"
																												: params.value === "service_all"
																													? "Service All"
																													: "";
			},
			renderCell: (cellValues: any) => {
				const data = DashboardData.DashboardData.find((user: any) => user._id === cellValues.row.id);
				return data?.access_level?.split(",").map((item: any, index: number) => {
					return (
						<ChipData
							key={index}
							id={item}
							label={
								item === "property"
									? "Property"
									: item === "service"
										? "Service"
										: item === "kyc"
											? "KYC"
											: item === "iam"
												? "IAM"
												: item === "admin"
													? "Admin"
													: item === "super_admin"
														? "Super Admin"
														: item === "user_management"
															? "User Management"
															: item === "analytics"
																? "Analytics"
																: item === "subscriptions"
																	? "Subscriptions"
																	: item === "report"
																		? "Report"
																		: item === "bug_report"
																			? "Bug Report"
																			: item === "business_profiles"
																				? "Business Profiles"
																				: item === "post"
																					? "Post"
																					: item === "employees"
																						? "Employees"
																						: item === "activity_log"
																							? "Activity Log"
																							: item === "beegru_points_transaction"
																								? "Beegru Points Transaction"
																								: item === "boost_pricing_manual_overrides"
																									? "Manual Points Transaction"
																									: item === "cash_transaction"
																										? "Cash Transaction"
																										: item === "search_logs"
																											? "Search Logs"
																											: item === "logs"
																												? "Logs"
																												: item === "latest-changes"
																													? "Latest Changes"
																													: item === "translations"
																														? "Translations"
																														: item === "leads"
																															? "Leads"
																															: item === "boost"
																																? "Boost"
																																: item === "reviews"
																																	? "Reviews"
																																	: item === "property_all"
																																		? "Property All"
																																		: item === "service_all"
																																			? "Service All"
																																			: ""
							}
							variant="filled"
							onDelete={() => {
								deleteAccess(item, cellValues);
							}}
							deleteIcon={<Close />}
						/>
					);
				});
			},
			renderEditCell: (cellValues: any) => {
				const data = DashboardData.DashboardData.find((user: any) => user._id === cellValues.row.id);
				return (
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						fullWidth={true}
						value={data ? data.access_level : cellValues.value}
						onChange={(event: any) => handleChange(event, cellValues)}
					>
						{menuItems.map((item: any, index: number) => {
							return (
								<MenuItem
									value={item.value}
									key={index}
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
									}}
								>
									{item.label}
								</MenuItem>
							);
						})}
					</Select>
				);
			},
		},
		{
			field: "action",
			headerName: "Action",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Button
						variant="outlined"
						color="primary"
						sx={{
							borderRadius: "0.5rem",
						}}
						onClick={() => {
							removeAdminAccess(cellValues);
						}}
					>
						Remove Access
					</Button>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<ButtonContainer>
				<GridToolbarContainer>
					<GridToolbarColumnsButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarFilterButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarDensitySelector
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarExport
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
				</GridToolbarContainer>
				<Button
					variant="outlined"
					color="primary"
					startIcon={<Add />}
					sx={{
						borderRadius: "0.5rem",
						margin: "1rem 1rem 0rem 1rem",
					}}
					onClick={() => {
						handleClickGrandAccess();
					}}
				>
					Grant Access
				</Button>
			</ButtonContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on breadcrumb

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			IAM
		</Typography>,
	];

	return (
		<MainContainer>
			<GrandAccessModal
				open={openAccessModal}
				onClose={handleClose}
			/>
			<HeadContainer>
				<HeadText>IAM</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{DashboardData ? (
				DashboardData.accessLevel ? (
					DashboardData.accessLevel.includes("super_admin") ? (
						DashboardData.AnalyticsData || DashboardData.ActivityLogData ? null : (
							<Box sx={{ height: "80vh", padding: " 1rem", width: "100%" }}>
								<DataGridPremium
									disableRowSelectionOnClick={false}
									slots={{ toolbar: GridToolbar }}
									rows={DashboardData.DashboardData?.map((item: any) => {
										return {
											id: item._id,
											userId: item._id,
											username: item.first_name + " " + item.last_name,
											phonenumber: item.phone,
											access_level: item.access_level,
										};
									})}
									columns={columns}
									pagination={true}
									paginationMode="server"
									onPaginationModelChange={(paginationModel) => {
										setPaginationModel(paginationModel);
									}}
									rowCount={rowCountState}
									pageSizeOptions={[100]}
									sx={{
										backgroundColor: "#FFFFFF",
										"& > .MuiDataGrid-columnSeparator": {
											display: "none",
										},
										"& .MuiDataGrid-cell": {
											borderBottom: "none",
										},
									}}
								/>
							</Box>
						)
					) : (
						<YouDontHaveAccess>
							<Typography
								variant="h6"
								color="text.secondary"
							>
								You don&apos;t have access to this page
							</Typography>
						</YouDontHaveAccess>
					)
				) : (
					<YouDontHaveAccess>
						<Typography
							variant="h6"
							color="text.secondary"
						>
							You don&apos;t have access to this page
						</Typography>
					</YouDontHaveAccess>
				)
			) : (
				<YouDontHaveAccess>
					<Typography
						variant="h6"
						color="text.secondary"
					>
						You don&apos;t have access to this page
					</Typography>
				</YouDontHaveAccess>
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default IAMDatatable;
