import { Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, DialogActions, DialogTitle, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";


const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));


const PDFViewModal = ({ open, onClose, pdfUrl }: { open: any; onClose: any; pdfUrl: string }) => {
	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				fullScreen={true}
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						color: "primary.main",
					}}
					component="div"
				>
					Upload Verified Document
					<Close
						aria-label="close"
						onClick={() => {
							onClose();
						}}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<DialogContent>
					<iframe
						src={`/api/PDF-url/${pdfUrl}`}
						width="100%"
						height="100%"
					></iframe>
				</DialogContent>
				<DialogActions>
					<ViewOrUploadButton
						variant="outlined"
						sx={{
							color: "#FC8019",
						}}
						autoFocus
						onClick={() => {
							onClose();
						}}
					>
						Cancel
					</ViewOrUploadButton>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default PDFViewModal;
