/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/
import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";
import ReactTimeAgo from "react-time-ago";

import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Chip, debounce, Divider, Link, Typography, useTheme } from "@mui/material";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { ObjectId } from "mongodb";
import { useRouter } from "next/router";
import LoadingModal from "../../../modal/LoadingModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "user"
		? {
				backgroundColor: "rgba(244, 67, 54, 0.5)",
			}
		: id === "agent"
			? {
					backgroundColor: "#FFD700",
				}
			: id === "developer"
				? {
						backgroundColor: "#d5d5d7",
					}
				: id === "landowner"
					? {
							backgroundColor: "rgb(30,144,255,0.6)",
						}
					: id === "professional"
						? {
								backgroundColor: " #00FF00",
							}
						: {
								backgroundColor: "rgba(255, 255, 255, 0.002)",
							},
);

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "2rem",
	width: "2rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

TimeAgo.addLocale(en);

const ReviewsDatatable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	const [openModal, setOpenMoadal] = React.useState(false);

	/*

  & Next, let's define a local state for the user id to add beegru points

  */
	const [userId, setUserId] = React.useState<string | ObjectId>("");

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);
	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount || 0);
		setDashboardData(DashboardData.DashboardData || []);
	}, [DashboardData]);

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "boost-table/boost-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "body",
			headerName: "Body",
			width: 120,
		},
		{
			field: "media",
			headerName: "Media",
			width: 100,
		},
		{
			field: "created_by",
			headerName: "Created by",
			width: 200,
			renderCell: (cellValues: any) => {
				return cellValues.value?.is_business_profile ? (
					<Link
						href={
							`https://beegru.com/${
								cellValues.value.business_profile_id ? cellValues.value.business_profile_id.business_profile_type : ""
							}s/` + (cellValues.value.business_profile_id ? cellValues.value.business_profile_id.slug : "")
						}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value.business_profile_id ? cellValues.value.business_profile_id.title : ""}
					</Link>
				) : (
					<Link
						href={
							"https://beegru.com/users/" +
							(cellValues.value ? (cellValues.value.user_id ? cellValues.value.user_id._id : "") : "")
						}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value
							? cellValues.value.user_id
								? cellValues.value.user_id.first_name + " " + cellValues.value.user_id.last_name
								: "--"
							: "--"}
					</Link>
				);
			},
		},
		{
			field: "rating",
			headerName: "Rating",
			width: 100,
		},
		{
			field: "reviewed_entity_type",
			headerName: "Reviewed Entity Type",
			width: 200,
		},
		{
			field: "reviewed_entity_id",
			headerName: "Reviewed Entity Id",
            width: 200,
            renderCell: (cellValues: any) => {
                return cellValues.value ? (
									<Link
										href={
											`https://beegru.com/${
												cellValues.row.reviewed_entity_type === "business_profile"
													? cellValues.value.business_profile_type + "s"
													: cellValues.row.reviewed_entity_type === "user"
														? "users"
														: cellValues.row.reviewed_entity_type === "property"
															? "properties"
															: cellValues.row.reviewed_entity_type === "service"
																? "services"
																: ""
											}/` + cellValues.value.slug
										}
										target="_blank"
										sx={{
											textDecoration: "none",
										}}
									>
										{cellValues.value ? cellValues.value.title : ""}
									</Link>
								) : (
									"--"
								);
            },
		},
		{
			field: "reviewed_neighbourhood",
			headerName: "Reviewed Neighbourhood",
			width: 200,
		},
		{
			field: "upvotes",
			headerName: "Upvotes",
			width: 100,
			filterable: false,
		},

		{
			field: "downvotes",
			headerName: "Downvotes",
			width: 100,
			filterable: false,
		},
		{
			field: "views",
			headerName: "Views",
			width: 100,
			filterable: false,
		},
		{
			field: "tags",
			headerName: "Tags",
			width: 100,
		},

		{
			field: "created_at",
			headerName: "Created At",
			width: 200,
			filterable: false,
		},
		{
			field: "updated_at",
			headerName: "Updated At",
			width: 150,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ReactTimeAgo
						date={cellValues.value ? cellValues.value : 0}
						locale="en-US"
					/>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			Reviews
		</Typography>,
	];

	console.log("DashboardData", DashboardData);

	return (
		<MainContainer>
			<LoadingModal open={openModal} />
			<HeadContainer>
				<HeadText>Reviews</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{DashboardData.accessLevel &&
			((DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("reviews")) ||
				DashboardData.accessLevel.includes("super_admin")) ? (
				DashboardData.AnalyticsData || DashboardData.ActivityLogData ? null : (
					<Box sx={{ height: "80vh", padding: " 1rem", width: "100%" }}>
						<DataGridPremium
							disableRowSelectionOnClick={false}
							slots={{ toolbar: GridToolbar }}
							rows={dashboardData?.map((item: any) => {
								const created_at_date = new Date(item.created_at);

								return {
									id: item._id,
									created_by: item.created_by,
									body: item.body ? item.body : "--",
									media: item.media,
									rating: item.rating,
									reviewed_entity_type: item.reviewed_entity_type,
									reviewed_entity_id: item.reviewed_entity_id,
									reviewed_neighbourhood: item.reviewed_neighbourhood,
									upvotes: item.upvotes,
									downvotes: item.downvotes,
									views: item.views,
									tags: item.tags,
									created_at: item? created_at_date.toDateString() : "--",
									updated_at: item.updated_at,
								};
							})}
							columns={columns}
							pagination={true}
							paginationMode="server"
							onPaginationModelChange={(paginationModel) => {
								setPaginationModel(paginationModel);
							}}
							rowCount={rowCountState}
							pageSizeOptions={[100]}
							filterMode="server"
							onFilterModelChange={(newFilterModel) => {
								setQueryOptions(newFilterModel);
								FilteringFunction(newFilterModel);
							}}
							sx={{
								backgroundColor: "#FFFFFF",
								"& > .MuiDataGrid-columnSeparator": {
									display: "none",
								},
								"& .MuiDataGrid-cell": {
									borderBottom: "none",
								},
								"& .MuiDataGrid-virtualScroller": {
									scrollbarWidth: "thin",
									scrollbarColor: "#F76C35 #FFFFFF",

									borderRadius: "0.5rem",
								},
							}}
						/>
					</Box>
				)
			) : (
				<YouDontHaveAccess>
					<Typography
						variant="h6"
						color="text.secondary"
					>
						You don&apos;t have access to this page
					</Typography>
				</YouDontHaveAccess>
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default ReviewsDatatable;
