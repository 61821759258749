import { Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const MainContainer = styled(DialogContentText)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
}));

const InnerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1.2rem",
}));
const SubContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	padding: "1rem 1rem 1rem 1rem",
	margin: "1rem",
	width: "100%",
	border: " 1px solid black",
	borderRadius: "1rem",
	lineHeight: "2rem",
}));

const LanguageModal = ({ open, onClose, language }: { open: any; onClose: any; language: any }) => {
	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						color: "primary.main",
					}}
				>
					Translations
					<Close
						aria-label="close"
						onClick={onClose}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<DialogContent>
					<InnerContainer>
						<MainContainer>source text : {language ? language.source_language : ""}</MainContainer>
						<SubContainer>{language ? language.source_text : ""}</SubContainer>
					</InnerContainer>
					<InnerContainer>
						<MainContainer>target text : {language ? language.target_language : ""}</MainContainer>
						<SubContainer>{language ? language.target_text : ""}</SubContainer>
					</InnerContainer>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default LanguageModal;
