/* Common import */
import React from "react";

//@mui imports

import {
	AdminPanelSettings,
	Analytics,
	ArrowBack,
	Bolt,
	BugReport,
	BusinessCenter,
	Construction,
	DocumentScanner,
	ExpandLess,
	ExpandMore,
	Group,
	HolidayVillage,
	Image,
	LocalActivity,
	LocalAtmTwoTone,
	ManageAccounts,
	ManageSearch,
	MarkUnreadChatAlt,
	Menu,
	Person,
	ProductionQuantityLimits,
	Report,
	RequestQuote,
	Reviews,
	RocketLaunch,
	Shield,
	Subscriptions,
	Translate,
} from "@mui/icons-material";

import {
	Collapse,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography,
} from "@mui/material";
import { styled } from "@mui/system";

/* component import */

/* Redux import */

/* Styling */

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-end",
	width: "20rem",
	gap: "1rem",
	borderRight: "1px solid rgba(0, 0, 0, 0.23)",
	padding: "1rem 1rem",
	// height: "calc(100vh - 4rem)",
	// overflowY: "scroll",
}));

const UserContent = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	height: "4.5rem",
	padding: "0.5rem",
	backgroundColor: "#FAFAFA",
	boxShadow: "none",
	gap: "1rem",
}));

const ImageContent = styled("img")(({ theme }) => ({
	width: "2.5rem",
	height: "2.5rem",
	borderRadius: "0.25rem",
}));

const HeadingContent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: "400",
	lineHeight: "1.5rem",
	width: "100%",
	textAlign: "left",
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	fontWeight: "400",
	lineHeight: "1.251rem",
	letterSpacing: "0.009rem",
	color: "rgba(0, 0, 0, 0.54)",
	width: "100%",
	textAlign: "left",
}));

const ListContainer = styled(List)(({ theme }) => ({}));

const MenuContent = ({
	session,
	selectedList,
	handleListItemClick,
	collapseState,
}: {
	session: any;
	selectedList: string;
	handleListItemClick: (event: any, name: string) => void;
	collapseState: Function;
}) => {
	const [openModeration, setOpenModeration] = React.useState(true);
	const [openKyC, setOpenKyC] = React.useState(false);
	const [openTransaction, setOpenTransaction] = React.useState(false);
	const [collapse, setCollapse] = React.useState(false);
	const [collapseKyc, setCollapseKyc] = React.useState(false);

	const handleClick = () => {
		setOpenModeration(!openModeration);
	};

	const handleClickKyc = () => {
		setOpenKyC(!openKyC);
	};

	const handleClickTransaction = () => {
		setOpenTransaction(!openTransaction);
	};

	const handleClickCollapse = () => {
		setCollapse(!collapse);
	};

	const handleClickKycCollapse = () => {
		setCollapseKyc(!collapseKyc);
	};

	React.useEffect(() => {
		collapseState(collapse);
	}, [collapse, collapseState]);

	return (
		<Collapse
			orientation="horizontal"
			in={!collapse}
			collapsedSize={80}
		>
			<ContentContainer>
				<UserContent>
					<IconButton
						onClick={() => {
							handleClickCollapse();
							openModeration ? handleClick() : null;
							openTransaction ? handleClickTransaction() : null;
							openKyC ? handleClickKyc() : null;
						}}
					>
						{collapse ? <Menu /> : <ArrowBack />}
					</IconButton>
					<HeadingContent>
						<HeadText>
							{session ? (session.user ? session.user.name : "You're not signed in") : "You're not signed in"}
						</HeadText>
						<SubText>{session ? (session.user ? session.user.email : "") : ""}</SubText>
					</HeadingContent>
					<ImageContent
						src={
							session
								? session.user
									? session.user.image
									: "https://www.w3schools.com/howto/img_avatar.png"
								: "https://www.w3schools.com/howto/img_avatar.png"
						}
						alt="User"
					/>
				</UserContent>

				<List
					component="nav"
					aria-labelledby="nested-list-subheader"
					sx={{ width: "100%", gap: "1rem" }}
				>
					<ListItemButton
						onClick={() => {
							handleClick();
							{
								collapse ? handleClickCollapse() : null;
							}
						}}
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
					>
						<ListItemIcon
							sx={
								openModeration
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Shield />
						</ListItemIcon>
						<ListItemText primary="Moderation" />
						{openModeration ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse
						in={openModeration}
						timeout="auto"
						unmountOnExit
					>
						<List
							component="div"
							disablePadding
						>
							<ListItemButton
								sx={{
									pl: 4,
									marginTop: "1rem",
									borderRadius: "0.5rem",

									padding: "0.5rem 2rem",
									"&:hover": {
										backgroundColor: "rgba(255, 117, 24, 0.16)",
									},
								}}
								onClick={(event) => handleListItemClick(event, "property")}
							>
								<ListItemIcon
									sx={
										selectedList === "property"
											? { color: "rgb(247, 108, 53)" }
											: {
													color: "rgba(0, 0, 0, 0.54)",
												}
									}
								>
									<HolidayVillage />
								</ListItemIcon>
								<ListItemText
									primary="Property"
									sx={
										selectedList === "property"
											? { color: "rgb(247, 108, 53)" }
											: {
													color: "#000000",
												}
									}
								/>
							</ListItemButton>
							<ListItemButton
								sx={{
									pl: 4,

									borderRadius: "0.5rem",

									padding: "0.5rem 2rem",
									"&:hover": {
										backgroundColor: "rgba(255, 117, 24, 0.16)",
									},
								}}
								onClick={(event) => handleListItemClick(event, "service")}
							>
								<ListItemIcon
									sx={
										selectedList === "service"
											? { color: "rgb(247, 108, 53)" }
											: {
													color: "rgba(0, 0, 0, 0.54)",
												}
									}
								>
									<Construction />
								</ListItemIcon>
								<ListItemText
									primary="Service"
									sx={
										selectedList === "service"
											? { color: "rgb(247, 108, 53)" }
											: {
													color: "#000000",
												}
									}
								/>
							</ListItemButton>
							<ListItemButton
								sx={{
									pl: 4,

									borderRadius: "0.5rem",

									padding: "0.5rem 2rem",
									"&:hover": {
										backgroundColor: "rgba(255, 117, 24, 0.16)",
									},
								}}
								onClick={(event) => handleListItemClick(event, "post")}
							>
								<ListItemIcon
									sx={
										selectedList === "post"
											? { color: "rgb(247, 108, 53)" }
											: {
													color: "rgba(0, 0, 0, 0.54)",
												}
									}
								>
									<Image />
								</ListItemIcon>
								<ListItemText
									primary="Post"
									sx={
										selectedList === "post"
											? { color: "rgb(247, 108, 53)" }
											: {
													color: "#000000",
												}
									}
								/>
							</ListItemButton>
						</List>
					</Collapse>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "business-profiles")}
					>
						<ListItemIcon
							sx={
								selectedList === "business-profiles"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<BusinessCenter />
						</ListItemIcon>
						<ListItemText primary="Business Profiles" />
						{/* {open ? <ExpandLess /> : <ExpandMore />}  */}
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "boost")}
					>
						<ListItemIcon
							sx={
								selectedList === "boost"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<RocketLaunch />
						</ListItemIcon>
						<ListItemText primary="Boost" />
						{/* {open ? <ExpandLess /> : <ExpandMore />}  */}
					</ListItemButton>

					<List
						component="nav"
						aria-labelledby="nested-list-subheader"
						sx={{ width: "100%", gap: "1rem" }}
					>
						<ListItemButton
							onClick={() => {
								handleClickKyc();
								{
									collapseKyc ? handleClickKycCollapse() : null;
								}
							}}
							sx={{
								borderRadius: "0.5rem",
								padding: "0.5rem 1rem",
								"&:hover": {
									backgroundColor: "rgba(255, 117, 24, 0.16)",
								},
							}}
						>
							<ListItemIcon
								sx={
									openKyC
										? { color: "rgb(247, 108, 53)" }
										: {
												color: "rgba(0, 0, 0, 0.54)",
											}
								}
							>
								<DocumentScanner />
							</ListItemIcon>
							<ListItemText primary="KYC" />
							{openKyC ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
						<Collapse
							in={openKyC}
							timeout="auto"
							unmountOnExit
						>
							<List
								component="div"
								disablePadding
							>
								<ListItemButton
									sx={{
										pl: 4,

										borderRadius: "0.5rem",

										padding: "0.5rem 2rem",
										"&:hover": {
											backgroundColor: "rgba(255, 117, 24, 0.16)",
										},
									}}
									onClick={(event) => handleListItemClick(event, "user-kyc")}
								>
									<ListItemIcon
										sx={
											selectedList === "user-kyc"
												? { color: "rgb(247, 108, 53)" }
												: {
														color: "rgba(0, 0, 0, 0.54)",
													}
										}
									>
										<Person />
									</ListItemIcon>
									<ListItemText primary="User-KYC" />
								</ListItemButton>{" "}
								<ListItemButton
									sx={{
										pl: 4,

										borderRadius: "0.5rem",

										padding: "0.5rem 2rem",
										"&:hover": {
											backgroundColor: "rgba(255, 117, 24, 0.16)",
										},
									}}
									onClick={(event) => handleListItemClick(event, "business-kyc")}
								>
									<ListItemIcon
										sx={
											selectedList === "business-kyc"
												? { color: "rgb(247, 108, 53)" }
												: {
														color: "rgba(0, 0, 0, 0.54)",
													}
										}
									>
										<BusinessCenter />
									</ListItemIcon>
									<ListItemText primary="Business-KYC" />
								</ListItemButton>
							</List>
						</Collapse>
					</List>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "analytics")}
					>
						<ListItemIcon
							sx={
								selectedList === "analytics"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Analytics />
						</ListItemIcon>
						<ListItemText primary="Analytics" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "leads")}
					>
						<ListItemIcon
							sx={
								selectedList === "leads"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<ProductionQuantityLimits />
						</ListItemIcon>
						<ListItemText primary="Leads" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "iam")}
					>
						<ListItemIcon
							sx={
								selectedList === "iam"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<AdminPanelSettings />
						</ListItemIcon>
						<ListItemText primary="IAM" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "user-mgmnt")}
					>
						<ListItemIcon
							sx={
								selectedList === "user-mgmnt"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<ManageAccounts />
						</ListItemIcon>
						<ListItemText primary="User Management" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "bug-report")}
					>
						<ListItemIcon
							sx={
								selectedList === "bug-report"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<BugReport />
						</ListItemIcon>
						<ListItemText primary="Bug Report" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "report")}
					>
						<ListItemIcon
							sx={
								selectedList === "report"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Report />
						</ListItemIcon>
						<ListItemText primary="Reports" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "subscriptions")}
					>
						<ListItemIcon
							sx={
								selectedList === "subscriptions"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Subscriptions />
						</ListItemIcon>
						<ListItemText primary="Subscriptions" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "employees")}
					>
						<ListItemIcon
							sx={
								selectedList === "employees"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Group />
						</ListItemIcon>
						<ListItemText primary="Employees" />
						{/* {open ? <ExpandLess /> : <ExpandMore />}  */}
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "activity_log")}
					>
						<ListItemIcon
							sx={
								selectedList === "activity_log"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<LocalActivity />
						</ListItemIcon>
						<ListItemText primary="Activity Log" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "logs")}
					>
						<ListItemIcon
							sx={
								selectedList === "logs"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<ManageSearch />
						</ListItemIcon>
						<ListItemText primary="Logs" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "beegru_points_transaction")}
					>
						<ListItemIcon
							sx={
								selectedList === "beegru_points_transaction"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<LocalAtmTwoTone />
						</ListItemIcon>
						<ListItemText primary="Beegru Points transaction" />
					</ListItemButton>
					{/* <ListItemButton
            sx={{
              borderRadius: "0.5rem",
              padding: "0.5rem 1rem",
              width: "100%",
              "&:hover": {
                backgroundColor: "rgba(255, 117, 24, 0.16)",
              },
            }}
            onClick={(event) =>
              handleListItemClick(event, "manual_points_transaction")
            }
          >
            <ListItemIcon
              sx={
                selectedList === "manual_points_transaction"
                  ? { color: "rgb(247, 108, 53)" }
                  : {
                      color: "rgba(0, 0, 0, 0.54)",
                    }
              }
            >
              <CurrencyExchangeTwoTone />
            </ListItemIcon>
            <ListItemText primary="Manual Points transaction" />
          </ListItemButton> */}
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "cash_transaction")}
					>
						<ListItemIcon
							sx={
								selectedList === "cash_transaction"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<RequestQuote />
						</ListItemIcon>
						<ListItemText primary="Cash transactions" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "search_logs")}
					>
						<ListItemIcon
							sx={
								selectedList === "search_logs"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<ManageSearch />
						</ListItemIcon>
						<ListItemText primary="Search Logs" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "boost_pricing_manual_overrides")}
					>
						<ListItemIcon
							sx={
								selectedList === "boost_pricing_manual_overrides"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Bolt />
						</ListItemIcon>
						<ListItemText primary="Boost pricing manual overrides" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "latest-changes")}
					>
						<ListItemIcon
							sx={
								selectedList === "latest-changes"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<MarkUnreadChatAlt />
						</ListItemIcon>
						<ListItemText primary="Latest Changes" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "translations")}
					>
						<ListItemIcon
							sx={
								selectedList === "translations"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Translate />
						</ListItemIcon>
						<ListItemText primary="Translations" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "reviews")}
					>
						<ListItemIcon
							sx={
								selectedList === "reviews"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Reviews />
						</ListItemIcon>
						<ListItemText primary="Reviews" />
					</ListItemButton>
				</List>
			</ContentContainer>
		</Collapse>
	);
};

export default MenuContent;
