// common components
import { Circle, GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import React from "react";

// styled components

type Props = React.HTMLProps<HTMLDivElement> & {
	width?: string;
	height?: string;
	autoZoom?: boolean;
	zoomLevel?: number;
	draggable?: boolean;
	showRadius?: boolean;
	mapOptions?: MapOptions;
	location?: LocationData;
	radiusOptions?: RadiusOptions;
	onPinDrag?: (data: any[]) => void;
};

type LatLng = {
	lat: number;
	lng: number;
};

type LocationData = {
	lat?: number;
	lng?: number;
	radius?: number;
};

export interface MapOptions {
	clickableIcons?: boolean;
	disableDefaultUI?: boolean;
}

export interface RadiusOptions {
	zIndex?: number;
	visible?: boolean;
	editable?: boolean;
	fillColor?: string;
	clickable?: boolean;
	draggable?: boolean;
	fillOpacity?: number;
	strokeColor?: string;
	strokeWeight?: number;
	strokeOpacity?: number;
}

const defaultMapOptions = { disableDefaultUI: true, clickableIcons: false };

const librariesToLoad: ("places" | "drawing" | "geometry" | "localContext" | "visualization")[] = [
	"places",
	"drawing",
	"geometry",
];

const defaultCircleOptions = {
	zIndex: 3,
	visible: true,
	strokeWeight: 2,
	editable: false,
	clickable: false,
	draggable: false,
	fillOpacity: 0.2,
	fillColor: "red",
	strokeColor: "red",
	strokeOpacity: 0.5,
};

const MapCard = ({
	style,
	location,
	width = "100%",
	zoomLevel = 20000,
	height = "100%",
	autoZoom = false,
	showRadius = false,
	mapOptions = defaultMapOptions,
	radiusOptions = defaultCircleOptions,
	...props
}: Props) => {
	const [showRadiusView, setShowRadiusView] = React.useState<boolean>(false);
	const [locationData, setLocationData] = React.useState<LocationData | undefined>();

	const containerStyles = {
		// width: "100%",
		display: "flex",
		overflow: "hidden",
		background: "#EEEEEE",
		borderRadius: "16px",
	};

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY || "",
		libraries: librariesToLoad as any,
	});

	// Memo
	const [center, zoom] = React.useMemo(() => {
		const center: LatLng = {
			lat: locationData && locationData?.lat ? locationData?.lat : 43,
			lng: locationData && locationData?.lng ? locationData?.lng : -80,
		};
		let zoom = 1000;
		if (autoZoom) {
			zoom = 14;
			if (locationData && locationData.lat && locationData.lng && locationData.radius) {
				zoom = Math.log2((zoomLevel * Math.cos((locationData.lat * Math.PI) / 180)) / locationData.radius);
			}
		}
		return [center, zoom];
	}, [autoZoom, locationData, zoomLevel]);

	const onDragPinChange = async (event: any) => {
		const latlng = event?.latLng;
		const lat = await latlng?.lat();
		const lng = await latlng?.lng();
		const geocoder = new google.maps.Geocoder();
		const data: any[] = await geocoder.geocode({ location: latlng }).then((res: any) => res);
		setLocationData((prev: any) => ({ ...prev, lat, lng }));
		setShowRadiusView(true);
		onDragPinChange(data[0]);
	};

	React.useEffect(() => {
		setLocationData(location);
		setShowRadiusView(showRadius);
    }, [location, showRadius]);


	return (
		<div
			{...props}
			// style={{ ...containerStyles, ...style }}
		>
			{/* <Collapse
				in={!!locationData?.lat && !!locationData?.lng}
				sx={{ width }}
			> */}
				{isLoaded && (
					<GoogleMap
						zoom={zoom}
						center={center}
						options={{ ...defaultMapOptions, ...mapOptions }}
						mapContainerStyle={{ width:"100%", height:"20rem", borderRadius: "0.5rem" }}
					>
						{locationData && locationData?.lat && locationData?.lng && (
							<Marker
								draggable
								position={center}
								onDragStart={() => setShowRadiusView(false)}
								onDragEnd={onDragPinChange}
							>
								{locationData.radius && showRadiusView && (
									<Circle
										center={center}
										radius={locationData?.radius * 1000}
										options={{ ...defaultCircleOptions, ...radiusOptions }}
									></Circle>
								)}
							</Marker>
						)}
					</GoogleMap>
				 )}
			{/* </Collapse> */}
		</div>
	);
};

export default MapCard;
