/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Chip, debounce, Divider, Link, Typography } from "@mui/material";
import { useRouter } from "next/router";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "resolved"
		? {
				backgroundColor: "rgba(76, 175, 80, 0.5)",
			}
		: id === "unresolved"
			? {
					backgroundColor: "#d5d5d7",
					color: "#000000",
				}
			: {
					backgroundColor: "rgba(255, 255, 255, 0.002)",
				},
);

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const LeadsTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	const [openModal, setOpenMoadal] = React.useState(false);

	const [openReport, setOpenReportModal] = React.useState(false);

	const [reportdata, setReportData] = React.useState<any>([]);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */
	React.useEffect(() => {
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount || 0);
		setDashboardData(DashboardData.DashboardData || []);
	}, [DashboardData]);

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "leads-table/leads-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	const columns = [
		{
			field: "name",
			headerName: "Name",
			width: 200,
		},
		{
			field: "phone",
			headerName: "Phone",
			width: 150,
		},

		{
			field: "source",
			headerName: "Source",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com" + cellValues.value}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "status",
			headerName: "status",
			width: 150,
		},
		{
			field: "notes",
			headerName: "notes",
			width: 180,
		},

		{
			field: "listing_type",
			headerName: "Listing Type",
			width: 150,
		},
		{
			field: "listing_slug",
			headerName: "Listing Slug",
			width: 120,
		},
		{
			field: "listing_title",
			headerName: "Listing Title",
			width: 150,
		},
		{
			field: "listing_owner",
			headerName: "Listing Owner",
			width: 150,
			filterable: false,
		},
		{
			field: "createdOn",
			headerName: "Created On",
			width: 150,
			type: "date" as const,
			valueGetter: (params: any) => {
				return new Date(params); // Ensure this returns a Date object
			},
			renderCell: (params: any) => {
				const date = new Date(params.value);
				return date.toLocaleDateString(); // Format the date as a string for display
			},
		},
		{
			field: "is_guest",
			headerName: "Guest",
			width: 150,
		},
		{
			field: "user_id",
			headerName: "User ID",
			width: 150,
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			Leads
		</Typography>,
	];

	return (
		<MainContainer>
			<HeadContainer>
				<HeadText>Leads</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{DashboardData.accessLevel &&
			((DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("leads")) ||
				DashboardData.accessLevel.includes("super_admin")) ? (
				DashboardData.AnalyticsData || DashboardData.ActivityLogData ? null : (
					<Box sx={{ height: "80vh", padding: " 1rem", width: "100%" }}>
						<DataGridPremium
							disableRowSelectionOnClick={false}
							slots={{ toolbar: GridToolbar }}
							rows={dashboardData?.map((item: any) => {
								return {
									id: item._id,
									name: item ? (item.name ? (item.name.length > 1 ? item.name : "--") : "--") : "--",
									phone: item ? (item.phone ? item.phone.country_code + item.phone.phone_number : "") : "",
									source: item.source,
									status: item.status,
									notes: item.notes,
									listing_type: item.listing_type,
									listing_slug: item.listing_slug,
									listing_title: item.listing_title,
									listing_owner: item
										? item.listing_owner
											? item.listing_owner.is_business_profile
												? item.listing_owner.business_profile_id
													? item.listing_owner.business_profile_id[0].title
													: ""
												: item.listing_owner.user_id
													? item.listing_owner.user_id[0].first_name + " " + item.listing_owner.user_id[0].last_name
													: ""
											: ""
										: "",
									is_guest: item.is_guest,
									user_id: item.user_id,
									createdOn: item ? new Date(item.created_at) : new Date(),
								};
							})}
							// rows={rows}
							columns={columns}
							pagination={true}
							paginationMode="server"
							onPaginationModelChange={(paginationModel) => {
								setPaginationModel(paginationModel);
							}}
							rowCount={rowCountState}
							pageSizeOptions={[100]}
							filterMode="server"
							onFilterModelChange={(newFilterModel) => {
								setQueryOptions(newFilterModel);
								FilteringFunction(newFilterModel);
							}}
							sx={{
								backgroundColor: "#FFFFFF",
								"& > .MuiDataGrid-columnSeparator": {
									display: "none",
								},
								"& .MuiDataGrid-cell": {
									borderBottom: "none",
								},
							}}
						/>
					</Box>
				)
			) : (
				<YouDontHaveAccess>
					<Typography
						variant="h6"
						color="text.secondary"
					>
						You don&apos;t have access to this page
					</Typography>
				</YouDontHaveAccess>
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default LeadsTable;
