import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import { Button, DialogActions, DialogTitle, Divider, IconButton, Link, Typography, styled } from "@mui/material";

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));

const Container = styled("div")(({ theme }) => ({
	padding: "0rem 1rem 0rem 1rem",
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const InnerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "25rem",
	gap: "1.5rem",
	overflow: "scroll",
	scrollbarWidth: "none",
}));

const ContainerOne = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	// borderRight: "1px solid #E0E0E0",
	padding: "0rem 0rem 0rem 0rem",
	gap: "0.25rem",
	[theme.breakpoints.down("sm")]: {
		flexDirection: "row",
		gap: "0.75rem",
	},
}));
const MainHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "1.125rem",
	padding: "0.5rem 0rem 1rem 0rem",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	textTransform: "uppercase",
}));
const ImageContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "1rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: "600",
	lineHeight: "1.75rem",
	color: "#00000099",
	width: "100%",
}));

const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));

const SearchResultsModal = ({ open, onClose, searchResults }: { open: any; onClose: any; searchResults: any }) => {
	const businessProfiles = searchResults ? searchResults.businessProfiles : [];
	const properties = searchResults ? searchResults.properties : [];
	const services = searchResults ? searchResults.services : [];


	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{
						color: "primary.main",
						padding: "0.5rem 0rem 0rem 0rem",
					}}
				>
					<MainHeading>pages associated with this user</MainHeading>
					<Divider />
					<Close
						aria-label="close"
						onClick={() => {
							onClose();
						}}
						sx={{
							position: "absolute",
							right: "0.5rem",
							top: "0.5rem",
							color: "#F76C35",
						}}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<Container>
					<InnerContainer>
						<ContainerOne>
							<HeadText>User Profile (1)</HeadText>
							<ImageContainer>
								<Link
									href={`https://beegru.com/users/${searchResults?._id}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									{" "}
									{searchResults?._id}
								</Link>
							</ImageContainer>
						</ContainerOne>
						<Divider
							orientation="vertical"
							flexItem
						/>
						<ContainerOne>
							<HeadText>BusinessProfiles ({businessProfiles.length})</HeadText>
							{businessProfiles.map((profile: any, index: number) => (
								<ImageContainer key={index}>
									<Link
										href={`https://beegru.com/${profile.business_profile_type}s/${profile.slug}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{" "}
										{profile.title}{" "}
									</Link>
								</ImageContainer>
							))}
						</ContainerOne>
						<Divider
							orientation="vertical"
							flexItem
						/>
						<ContainerOne>
							<HeadText>Properties ({properties.length})</HeadText>
							{properties.map((property: any, index: number) => (
								<ImageContainer key={index}>
									<Link
										href={`https://beegru.com/properties/${property.slug}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{" "}
										{property.title}{" "}
									</Link>
								</ImageContainer>
							))}
						</ContainerOne>
						<Divider
							orientation="vertical"
							flexItem
						/>
						<ContainerOne>
							<HeadText>Services ({services.length})</HeadText>
							{services.map((service: any, index: number) => (
								<ImageContainer key={index}>
									<Link
										href={`https://beegru.com/services/${service.slug}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{" "}
										{service.title}{" "}
									</Link>
								</ImageContainer>
							))}
						</ContainerOne>
					</InnerContainer>
				</Container>

				<Divider />
				<DialogActions>
					<ViewOrUploadButton
						variant="outlined"
						sx={{
							color: "#FC8019",
						}}
						autoFocus
						onClick={() => {
							onClose();
						}}
					>
						Cancel
					</ViewOrUploadButton>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SearchResultsModal;
