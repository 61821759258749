/* Common Imports */

import PropTypes from "prop-types";

/* Vercel Analytics Imports */

import { Analytics } from "@vercel/analytics/react";

/* Global CSS Imports */

import "../public/fonts/poppins/poppins.css";
import "../public/fonts/ubuntu-mono/ubuntu-mono.css";
import "../public/styles/index.css";

/* Theme Imports */

import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { SessionProvider } from "next-auth/react";
import React from "react";
import CreateEmotionCache from "../components/layout-components/CreateEmotionCache";
import Theme from "../components/layout-components/Theme";
import playNotificationSound from "../lib/playNotificationSound";

export function reportWebVitals(metric: any) {
	/*

TODO:  Collect metrics!

  */
}
const MarketplaceControlPanel = ({ Component, pageProps: { session, ...pageProps } }: any) => {
	/* Let's describe a global useEffect to handle sessions */

	let sessionManager = React.useRef<any>();
	const localSession: any = pageProps.session ? pageProps.session : pageProps.sessionFromServer;

	const [newNotification, setNewNotification] = React.useState(null);

	const onConnectionOpen = React.useCallback(
		(event: any) => {
			/* As soon as the websocket connection opens, let's register a setInterval to send a heartbeat to the server and keep the session alive */


			const heartbeat = setInterval(
				() => {
					sessionManager?.current?.send(
						JSON.stringify({
							action: "heartbeat",
							data: {
								email: localSession.user.email,
							},
						}),
					);
				},
				parseInt(process.env.SESSION_HEARTBEAT_INTERVAL || "10000"),
			);

			return () => {
				clearInterval(heartbeat);
			};
		},
		[localSession],
	);

	const onMessage = React.useCallback((event: any) => {
		const response = JSON.parse(event.data);
		switch (response.action) {
			case "heartbeat":
				console.log(response.data.message);
				break;
			case "online":
				console.log(response.data.message);
				break;
			case "property-deleted":

				setNewNotification(response.data.payload);
				playNotificationSound();
				break;
			case "property-updated":

				setNewNotification(response.data.payload);
				playNotificationSound();
				break;
			case "property-created":

				setNewNotification(response.data.payload);
				playNotificationSound();
				break;
			case "service-deleted":

				setNewNotification(response.data.payload);
				playNotificationSound();
				break;
			case "service-updated":

				setNewNotification(response.data.payload);
				playNotificationSound();
				break;
			case "service-created":

				setNewNotification(response.data.payload);
				playNotificationSound();
				break;
			case "user-created":

				setNewNotification(response.data.payload);
				playNotificationSound();
				break;
			case "business-profile-created":

				setNewNotification(response.data.payload);
				playNotificationSound();
				break;
			case "business-profile-deleted":

				setNewNotification(response.data.payload);
				playNotificationSound();
				break;
			default:
				console.error("Unknown message received from server: ", event.data);
				break;
		}
	}, []);

	const onWebSocketError = React.useCallback((event: any) => {
		console.error("WebSocket error: ", event);
		/*

    & Ask the user to refresh the page in case of an error through a dialog box.

    */
	}, []);

	React.useEffect(() => {
		if (localSession && localSession.user && !sessionManager.current) {
			sessionManager.current = new WebSocket(
				(process.env.WEBSOCKET_URL || "") +
					"?email=" +
					encodeURIComponent(localSession.user.email) +
					"&x-vercel-protection-bypass=" +
					(process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || ""),
			);
			sessionManager.current.addEventListener("open", onConnectionOpen);
			sessionManager.current.addEventListener("message", onMessage);
			sessionManager.current.addEventListener("error", onWebSocketError);
			return () => {
				sessionManager.current.removeEventListener("open", onConnectionOpen);
				sessionManager.current.removeEventListener("message", onMessage);
				sessionManager.current.removeEventListener("error", onWebSocketError);
			};
		}
	}, [localSession, onConnectionOpen, onMessage, onWebSocketError]);
	return (
		<CacheProvider value={CreateEmotionCache()}>
			<ThemeProvider theme={Theme}>
				<CssBaseline />
				<SessionProvider session={localSession}>
					<Component
						{...pageProps}
						newNotification={newNotification}
					/>
				</SessionProvider>
				<Analytics />
			</ThemeProvider>
		</CacheProvider>
	);
};

export default MarketplaceControlPanel;

MarketplaceControlPanel.propTypes = {
	Component: PropTypes.elementType.isRequired,
	emotionCache: PropTypes.object,
	pageProps: PropTypes.object.isRequired,
	themeSetting: PropTypes.string,
};
