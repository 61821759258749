import Dialog from "@mui/material/Dialog";
import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Button, DialogActions, DialogTitle, Divider, styled, TextField, Typography } from "@mui/material";

import { Close } from "@mui/icons-material";
import { useRouter } from "next/router";

const SubHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	lineHeight: "2rem",
	fontSize: "1rem",
	padding: "0rem 0rem 0.5rem 0rem",
	textTransform: "capitalize",
}));

const MainHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "1.125rem",
	padding: "1.5rem 1.5rem 1rem 1.5rem",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	color: "#F46C34",
	textTransform: "capitalize",
}));

const ImageContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1.5rem 2rem 1.5rem 2rem",
	gap: "1rem",
}));

const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));

const AddRemoveBeegruPointsModal = ({
	open,
	onClose,
	userId,
	addOrRemove,
	session,
}: {
	open: any;
	onClose: any;
	userId: any;
	addOrRemove: string;
	session: any;
}) => {
	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the component. Here we have the following:

  & 1. Beegrupoints - a number that determines the number of beegru points to be added or removed (default: 0)

  & 2. reason - a string that determines the reason for adding or removing beegru points (default: "")

  */

	const [Beegrupoints, setBeegruPoints] = React.useState(0);

	const [reason, setReason] = React.useState("");

	/*

  & Next, let's define a function that will be called when the user clicks on the save button. This function will make a POST request to the /api/user-table/update-beegru-points endpoint to update the beegru points of the user.

  */

	const submit = async () => {
		await fetch(process.env.PRODUCTION_API_URL + "user-table/update-beegru-points", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				userId: userId,
				addOrRemove: addOrRemove,
				Beegrupoints: Beegrupoints,
				reason: reason,
				sessionEmail: session ? (session.user ? session.user.email : "") : "",
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					change: "true",
				},
			},
			undefined,
			{
				shallow: true,
			},
		);
		onClose();
		setBeegruPoints(0);
		setReason("");
	};

	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="sm"
				sx={{
					"& .MuiDialog-paper": {
						borderRadius: "1rem",
						boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
					},
				}}
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{}}
				>
					<MainHeading>{addOrRemove} Beegru Points</MainHeading>
					<Divider />
					<Close
						aria-label="close"
						onClick={() => {
							setReason("");
							setBeegruPoints(0);
							onClose();
						}}
						sx={{
							position: "absolute",
							right: "0.5rem",
							top: "0.8rem",
							color: "#F76C35",
						}}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>

				<ImageContainer>
					<SubHeading>{addOrRemove} :</SubHeading>
					<TextField
						id="outlined-basic"
						label="add/remove beegru points"
						variant="outlined"
						value={Beegrupoints}
						type="number"
						onChange={(e) => setBeegruPoints(parseInt(e.target.value))}
					/>
					<SubHeading>Reason :</SubHeading>
					<TextField
						rows={4}
						multiline
						style={{ width: "100%" }}
						placeholder="Enter the reason for adding or removing beegru points"
						onChange={(e) => setReason(e.target.value)}
					/>
				</ImageContainer>
				<DialogActions>
					<ViewOrUploadButton
						autoFocus
						variant="outlined"
						sx={{
							color: "#FC8019",
						}}
						onClick={onClose}
					>
						Cancel
					</ViewOrUploadButton>
					<ViewOrUploadButton
						variant="contained"
						onClick={submit}
					>
						Save
					</ViewOrUploadButton>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AddRemoveBeegruPointsModal;
