import {
	Alert,
	Button,
	CircularProgress,
	DialogActions,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	Switch,
	TextField,
	Typography,
	styled,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

import { useRouter } from "next/router";
import Mapbox from "./subComponents/MapPicker";
import DaysOfWeek from "./subComponents/dayofweek";
import OneOff from "./subComponents/oneOff";
import TimesOfDay from "./subComponents/timeOfday";

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
}));
const AntSwitch = styled(Switch)(({ theme }) => ({}));

const Container = styled("div")(({ theme }) => ({
	position: "absolute",
	zIndex: 1,
	top: 0,
	left: 0,
	height: "4rem",
	width: "100%",
}));

const ContentContainer = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	// gap: "1.5rem",
	width: "100%",
	// margin: "2rem 0rem 2rem 0rem",
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		alignItems: "center",
	},
}));

const DataContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "1rem",
	width: "100%",

	// padding: "0.25rem",
}));

const Heading = styled(Typography)(({ theme }) => ({
	width: "100%",
	fontWeight: "500",
	fontSize: "1.125rem",
	lineHeight: "1.25rem",
}));

const LatLngContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
}));

const Subcontainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "1rem",
	width: "100%",
	flexWrap: "wrap",
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "1rem",
	padding: "0rem 0rem 0.25rem 0rem",
	width: "100%",
}));

const CreateBoostModal = ({
	open,
	onClose,
	tableData,
	session,
}: {
	open: any;
	onClose: any;
	tableData: any;
	session: any;
}) => {
	const router = React.useRef(useRouter()).current;

	const [checkedState, setCheckedState] = React.useState(false);

	const [checked, setChecked] = React.useState(false);

	const [basePrice, setBasePrice] = React.useState<number | string>(4);

	const [multiplier, setMultiplier] = React.useState<number | string>(1);

	const [descripton, setDescripton] = React.useState<string>("");

	const [beegrupoints, setBeegrupoints] = React.useState<number | string>(4);

	const [basePriceError, setBasePriceError] = React.useState(false);

	const [multiplierError, setMultiplierError] = React.useState(false);

	const [locatonCoordinates, setLocatonCoordinates] = React.useState<any>([77.5946, 12.9716]); // [lat,lng]

	const [locationTitle, setLocationTitle] = React.useState<string>("");

	const [locationRadius, setLocationRadius] = React.useState<number | string>(0);

	const [starttime, setStarttime] = React.useState<string>();

	const [endtime, setEndtime] = React.useState<string>();

	const [buttonText, setButtonText] = React.useState("Create Boost Override");

	const date = new Date(2100, 1, 1, 0, 0, 0, 0);

	const [convertButton, setConvertButton] = React.useState(false);

	const [priceButton, setPriceButton] = React.useState(false);

	const [daysOfError, setDaysOfError] = React.useState(false);

	const [days, setDays] = React.useState<any>([
		{
			id: 1,
			day: "Monday",
			start_time: tableData ? tableData.days_of_week?.monday.activates_at : date,
			end_time: tableData ? tableData.days_of_week?.monday.deactivates_at : date,
			in_use: tableData ? tableData.days_of_week?.monday.in_use : false,
		},
		{
			id: 2,
			day: "Tuesday",
			start_time: tableData ? tableData.days_of_week?.tuesday.activates_at : date,
			end_time: tableData ? tableData.days_of_week?.tuesday.deactivates_at : date,
			in_use: tableData ? tableData.days_of_week?.tuesday.in_use : false,
		},
		{
			id: 3,
			day: "Wednesday",
			start_time: tableData ? tableData.days_of_week?.wednesday.activates_at : date,
			end_time: tableData ? tableData.days_of_week?.wednesday.deactivates_at : date,
			in_use: tableData ? tableData.days_of_week?.wednesday.in_use : false,
		},
		{
			id: 4,
			day: "Thursday",
			start_time: tableData ? tableData.days_of_week?.thursday.activates_at : date,
			end_time: tableData ? tableData.days_of_week?.thursday.deactivates_at : date,
			in_use: tableData ? tableData.days_of_week?.thursday.in_use : false,
		},
		{
			id: 5,
			day: "Friday",
			start_time: tableData ? tableData.days_of_week?.friday.activates_at : date,
			end_time: tableData ? tableData.days_of_week?.friday.deactivates_at : date,
			in_use: tableData ? tableData.days_of_week?.friday.in_use : false,
		},
		{
			id: 6,
			day: "Saturday",
			start_time: tableData ? tableData.days_of_week?.saturday.activates_at : date,
			end_time: tableData ? tableData.days_of_week?.saturday.deactivates_at : date,
			in_use: tableData ? tableData.days_of_week?.saturday.in_use : false,
		},
		{
			id: 7,
			day: "Sunday",
			start_time: tableData ? tableData.days_of_week?.sunday.activates_at : date,
			end_time: tableData ? tableData.days_of_week?.sunday.deactivates_at : date,
			in_use: tableData ? tableData.days_of_week?.sunday.in_use : false,
		},
	]);

	const [activationStrategy, setActivationStrategy] = React.useState("time_of_day");

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	const menuItems = [
		{
			id: 1,
			label: "Time of day",
			value: "time_of_day",
		},
		{
			id: 2,
			label: "Days of week",
			value: "days_of_week",
		},
		{
			id: 3,
			label: "One off",
			value: "one_off",
		},
		{
			id: 4,
			label: "Permanent",
			value: "permanent",
		},
	];

	const handleChangeforActivationStrategy = (event: SelectChangeEvent) => {
		setActivationStrategy(event.target.value as string);
		setDays([
			{
				id: 1,
				day: "Monday",
				start_time: new Date(2100, 1, 1, 0, 0, 0, 0),
				end_time: new Date(2100, 1, 1, 0, 0, 0, 0),
				in_use: false,
			},
			{
				id: 2,
				day: "Tuesday",
				start_time: new Date(2100, 1, 1, 0, 0, 0, 0),
				end_time: new Date(2100, 1, 1, 0, 0, 0, 0),
				in_use: false,
			},
			{
				id: 3,
				day: "Wednesday",
				start_time: new Date(2100, 1, 1, 0, 0, 0, 0),
				end_time: new Date(2100, 1, 1, 0, 0, 0, 0),
				in_use: false,
			},
			{
				id: 4,
				day: "Thursday",
				start_time: new Date(2100, 1, 1, 0, 0, 0, 0),
				end_time: new Date(2100, 1, 1, 0, 0, 0, 0),
				in_use: false,
			},
			{
				id: 5,
				day: "Friday",
				start_time: new Date(2100, 1, 1, 0, 0, 0, 0),
				end_time: new Date(2100, 1, 1, 0, 0, 0, 0),
				in_use: false,
			},
			{
				id: 6,
				day: "Saturday",
				start_time: new Date(2100, 1, 1, 0, 0, 0, 0),
				end_time: new Date(2100, 1, 1, 0, 0, 0, 0),
				in_use: false,
			},
			{
				id: 7,
				day: "Sunday",
				start_time: new Date(2100, 1, 1, 0, 0, 0, 0),
				end_time: new Date(2100, 1, 1, 0, 0, 0, 0),
				in_use: false,
			},
		]);
		setStarttime("");
		setEndtime("");
	};

	const submit = async () => {
		if (buttonText === "Create Boost Override") {
			await fetch(
				process.env.PRODUCTION_API_URL + "boost-pricing-manual-overrides/create-new-boost-pricing-manual-overrides",
				{
					method: "POST",
					cache: "no-store",
					headers: {
						"Content-Type": "text/plain",
					},
					body: JSON.stringify({
						locationTitle: locationTitle,
						coordinates: locatonCoordinates,
						applicableRadius: Number(locationRadius) * 1000,
						basePrice: beegrupoints,
						staticSurgeMultiplier: multiplier,
						useStaticSurgeMultiplier: checked,
						activated: checkedState,
						activationStrategy: activationStrategy,
						activatesAt: starttime?.toString(),
						deactivatesAt: endtime ? endtime.toString() : "",
						daysOfWeek: days,
						oneOff: starttime,
						description: descripton,
						createdBy: session ? (session.user ? session.user.email : "") : "",
					}),
				},
			);
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
					},
				},
				undefined,
				{
					shallow: true,
				},
			);

			setConvertButton(false);
			setPriceButton(false);
		} else {
			await fetch(
				process.env.PRODUCTION_API_URL + "boost-pricing-manual-overrides/update-boost-pricing-manual-overrides",
				{
					method: "POST",
					cache: "no-store",
					headers: {
						"Content-Type": "text/plain",
					},
					body: JSON.stringify({
						_id: tableData ? tableData._id : "",
						locationTitle: locationTitle,
						coordinates: locatonCoordinates,
						applicableRadius: Number(locationRadius) * 1000,
						basePrice: beegrupoints,
						staticSurgeMultiplier: multiplier,
						useStaticSurgeMultiplier: checked,
						activated: checkedState,
						activationStrategy: activationStrategy,
						activatesAt: starttime?.toString(),
						deactivatesAt: endtime ? endtime.toString() : "",
						daysOfWeek: days,
						oneOff: starttime,
						description: descripton,
						createdBy: session ? (session.user ? session.user.email : "") : "",
					}),
				},
			);
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		}
		onClose();
	};

	const CurrencyConvert = async () => {
		await fetch(`https://api.apilayer.com/currency_data/convert?to=SGD&from=INR&amount=${basePrice}`, {
			method: "GET",
			headers: {
				apikey: process.env.CURRENCY_API_KEY || "",
				"Content-Type": "application/json",
			},
			redirect: "follow",
		})
			.then((response) => response.text())
			.then((result) => {
				setBeegrupoints(JSON.parse(result).result);
				setConvertButton(false);
				setPriceButton(true);
			})
			.catch((error) => console.log("error", error));
	};

	React.useEffect(() => {
		if (tableData) {
			fetch("https://api.apilayer.com/currency_data/convert?to=INR&from=SGD&amount=" + tableData.base_price, {
				method: "GET",
				headers: {
					apikey: process.env.CURRENCY_API_KEY || "",
					"Content-Type": "application/json",
				},
				redirect: "follow",
			})
				.then((response) => response.text())
				.then((result) => setBasePrice(JSON.parse(result).result.toFixed(2)))
				.catch((error) => console.log("error", error));
		}
		//  else {
		//   fetch(
		//     "https://api.apilayer.com/currency_data/convert?to=SGD&from=INR&amount=4",
		//     {
		//       method: "GET",
		//       headers: {
		//         apikey: process.env.CURRENCY_API_KEY || "",
		//         "Content-Type": "application/json",
		//       },
		//       redirect: "follow",
		//     }
		//   )
		//     .then((response) => response.text())
		//     .then((result) => setBeegrupoints(JSON.parse(result).result))
		//     .catch((error) => console.log("error", error));
		// }
	}, [tableData]);

	React.useEffect(() => {
		setActivationStrategy(tableData ? tableData.activation_strategy : "time_of_day");
		setCheckedState(tableData ? tableData.activated : false);
		setChecked(tableData ? tableData.use_static_surge_multiplier : false);
		setBasePrice(tableData ? tableData.base_price : 4);
		setMultiplier(tableData ? tableData.static_surge_multiplier : 1);
		setDescripton(tableData ? tableData.rationale : "");
		setLocatonCoordinates(
			tableData ? (tableData.location ? tableData.location.coordinates : [77.5946, 12.9716]) : [77.5946, 12.9716],
		);
		setLocationTitle(tableData ? (tableData.location ? tableData.location.title : "") : "");
		setLocationRadius(tableData ? tableData.applicable_radius * 1000 : 0);
		setStarttime(tableData ? tableData.activates_at : "");
		setEndtime(tableData ? tableData.deactivates_at : "");
		setDays([
			{
				id: 1,
				day: "Monday",
				start_time: tableData ? tableData.days_of_week?.monday.activates_at : new Date(2100, 1, 1, 0, 0, 0, 0),
				end_time: tableData ? tableData.days_of_week?.monday.deactivates_at : new Date(2100, 1, 1, 0, 0, 0, 0),
				in_use: tableData ? tableData.days_of_week?.monday.in_use : false,
			},
			{
				id: 2,
				day: "Tuesday",
				start_time: tableData ? tableData.days_of_week?.tuesday.activates_at : new Date(2100, 1, 1, 0, 0, 0, 0),
				end_time: tableData ? tableData.days_of_week?.tuesday.deactivates_at : new Date(2100, 1, 1, 0, 0, 0, 0),
				in_use: tableData ? tableData.days_of_week?.tuesday.in_use : false,
			},
			{
				id: 3,
				day: "Wednesday",
				start_time: tableData ? tableData.days_of_week?.wednesday.activates_at : new Date(2100, 1, 1, 0, 0, 0, 0),
				end_time: tableData ? tableData.days_of_week?.wednesday.deactivates_at : new Date(2100, 1, 1, 0, 0, 0, 0),
				in_use: tableData ? tableData.days_of_week?.wednesday.in_use : false,
			},
			{
				id: 4,
				day: "Thursday",
				start_time: tableData ? tableData.days_of_week?.thursday.activates_at : new Date(2100, 1, 1, 0, 0, 0, 0),
				end_time: tableData ? tableData.days_of_week?.thursday.deactivates_at : new Date(2100, 1, 1, 0, 0, 0, 0),
				in_use: tableData ? tableData.days_of_week?.thursday.in_use : false,
			},
			{
				id: 5,
				day: "Friday",
				start_time: tableData ? tableData.days_of_week?.friday.activates_at : new Date(2100, 1, 1, 0, 0, 0, 0),
				end_time: tableData ? tableData.days_of_week?.friday.deactivates_at : new Date(2100, 1, 1, 0, 0, 0, 0),
				in_use: tableData ? tableData.days_of_week?.friday.in_use : false,
			},
			{
				id: 6,
				day: "Saturday",
				start_time: tableData ? tableData.days_of_week?.saturday.activates_at : new Date(2100, 1, 1, 0, 0, 0, 0),
				end_time: tableData ? tableData.days_of_week?.saturday.deactivates_at : new Date(2100, 1, 1, 0, 0, 0, 0),
				in_use: tableData ? tableData.days_of_week?.saturday.in_use : false,
			},
			{
				id: 7,
				day: "Sunday",
				start_time: tableData ? tableData.days_of_week?.sunday.activates_at : new Date(2100, 1, 1, 0, 0, 0, 0),
				end_time: tableData ? tableData.days_of_week?.sunday.deactivates_at : new Date(2100, 1, 1, 0, 0, 0, 0),
				in_use: tableData ? tableData.days_of_week?.sunday.in_use : false,
			},
		]);
		setButtonText(tableData ? "Update Boost Override" : "Create Boost Override");
	}, [tableData]);

	return (
		<>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="md"
				sx={{
					scrollbarColor: "#F76C35 #FFFFFF",
					"&::-webkit-scrollbar": {
						width: "0.4rem",
					},
				}}
			>
				<DialogTitle>create a new boost override</DialogTitle>

				<Container>
					<Stack
						direction="row"
						spacing={1}
						alignItems="center"
						sx={{
							width: "100%",
							justifyContent: "flex-end",
							padding: "1rem 1rem 0rem 0rem",
						}}
					>
						<Typography
							component={"span"}
							variant={"body2"}
							sx={{
								color: checkedState ? "#000000" : "#FF0000",
								fontWeight: checkedState ? "normal" : "bold",
								fontSize: "1rem",
							}}
						>
							OFF
						</Typography>
						<AntSwitch
							checked={checkedState}
							inputProps={{ "aria-label": "ant design" }}
							onChange={() => {
								setCheckedState(!checkedState);
							}}
						/>
						<Typography
							component={"span"}
							variant={"body2"}
							sx={{
								color: checkedState ? "#00FF00" : "#000000",
								fontWeight: checkedState ? "bold" : "normal",
								fontSize: "1rem",
							}}
						>
							ON
						</Typography>
					</Stack>
				</Container>
				<ContentContainer>
					<MainContainer>
						{tableData ? null : (
							<DataContainer>
								<LatLngContainer>
									<Heading
										sx={{
											width: "50%",
										}}
									>
										Base Price
									</Heading>
									<TextField
										id="outlined-basic"
										label="INR"
										variant="outlined"
										disabled={priceButton}
										error={basePriceError}
										helperText={basePriceError ? "Please enter a valid number" : ""}
										value={basePrice}
										onChange={(e) => {
											if (e.target.value.length > 0 && !isNaN(parseInt(e.target.value))) {
												setBasePrice(parseInt(e.target.value));
												setBasePriceError(false);
											} else {
												setBasePrice("");
												setBasePriceError(true);
											}
										}}
									/>
								</LatLngContainer>
								<LatLngContainer
									sx={{
										gap: "1rem",
									}}
								>
									<Button
										variant="contained"
										onClick={() => {
											CurrencyConvert();
											setConvertButton(true);
										}}
										sx={{
											width: "50%",
											color: "#FFFFFF",
										}}
										disabled={priceButton}
									>
										{convertButton ? (
											<CircularProgress
												size="1.5rem"
												color="inherit"
											/>
										) : (
											"Convert"
										)}
									</Button>
									<TextField
										id="outlined-basic"
										label="Beegru Points"
										variant="outlined"
										disabled={true}
										value={beegrupoints}
										onChange={(e) => {}}
									/>
								</LatLngContainer>
							</DataContainer>
						)}
						{tableData === null ? (
							<Alert
								variant="outlined"
								severity="warning"
								sx={{
									width: "100%",
									// padding: "1rem",
									margin: "1rem 0rem 1rem 0rem",
								}}
							>
								Base price is in Indian Rupees but converted and stored in Singaporean Dollars. You cannot change the
								base price after creating a manual boost override. In case you wish to change the base price, you will
								need to delete the boost override in question and create a new boost override with the new base price.
							</Alert>
						) : null}
						{priceButton || tableData ? (
							<>
								<Mapbox
									locationData={tableData ? tableData.location : null}
									locationRadius={tableData ? tableData.applicable_radius * 1000 : locationRadius}
									location={(location: any, title: any, radius: any) => {
										setLocatonCoordinates(location);
										setLocationTitle(title);
										setLocationRadius(radius);
									}}
								/>
								<Subcontainer>
									{tableData ? (
										<>
											<DataContainer>
												<LatLngContainer>
													<Heading
														sx={{
															width: "50%",
														}}
													>
														Base Price
													</Heading>
													<TextField
														id="outlined-basic"
														label="INR"
														variant="outlined"
														disabled={true}
													/>
												</LatLngContainer>
												<LatLngContainer
													sx={{
														gap: "1rem",
													}}
												>
													<Button
														variant="contained"
														sx={{
															width: "50%",
															color: "#FFFFFF",
														}}
														disabled={true}
													>
														Convert
													</Button>
													<TextField
														id="outlined-basic"
														label="Beegru Points"
														variant="outlined"
														disabled={true}
														value={beegrupoints}
														onChange={(e) => {}}
													/>
												</LatLngContainer>
											</DataContainer>
											<Alert
												variant="outlined"
												severity="info"
												sx={{
													width: "100%",
													// padding: "1rem",
													margin: "1rem 0rem 1rem 0rem",
												}}
											>
												Base price is converted and saved in SGD currency, you can not change the base price again
											</Alert>
										</>
									) : null}
									<DataContainer>
										<LatLngContainer>
											<Heading>surge multiplier</Heading>
											<TextField
												id="outlined-basic"
												label="Multiplier"
												variant="outlined"
												value={multiplier}
												error={multiplierError}
												disabled={!checked}
												helperText={
													multiplierError
														? "Please enter a valid number"
														: checked
															? ""
															: "Please turn on the surge multiplier to edit it"
												}
												onChange={(e) => {
													if (e.target.value.length > 0 && !isNaN(parseInt(e.target.value))) {
														setMultiplier(parseInt(e.target.value));
														setMultiplierError(false);
													} else {
														setMultiplier("");
														setMultiplierError(true);
													}
												}}
											/>
										</LatLngContainer>

										<Stack
											direction="row"
											spacing={1}
											alignItems="center"
											sx={{
												width: "100%",
												justifyContent: "flex-start",
												alignItems: "center",
												padding: "0rem 0rem 0rem 0rem",
											}}
										>
											<Typography
												component={"span"}
												variant={"body2"}
												sx={{
													color: checked ? "#000000" : "#FF0000",
													fontWeight: checked ? "normal" : "bold",
													fontSize: "1rem",
												}}
											>
												OFF
											</Typography>
											<AntSwitch
												checked={checked}
												inputProps={{ "aria-label": "ant design" }}
												onChange={handleChange}
											/>
											<Typography
												component={"span"}
												variant={"body2"}
												sx={{
													color: checked ? "#00FF00" : "#000000",
													fontWeight: checked ? "bold" : "normal",
													fontSize: "1rem",
												}}
											>
												ON
											</Typography>
										</Stack>
									</DataContainer>

									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "flex-start",
											alignItems: "center",
											gap: "1rem",
											width: "53%",
										}}
									>
										<Heading>activation strategy</Heading>
										<FormControl fullWidth>
											<InputLabel id="demo-simple-select-label">activation strategy</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={activationStrategy}
												label="activation strategy"
												onChange={(e) => {
													handleChangeforActivationStrategy(e);
												}}
											>
												{menuItems.map((item: any, index: number) => {
													return (
														<MenuItem
															value={item.value}
															key={index}
														>
															{item.label}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									</div>
									<Alert
										variant="outlined"
										severity="warning"
										sx={{
											width: "100%",
											// padding: "1rem",
											margin: "1rem 0rem 1rem 0rem",
										}}
									>
										When you change the activation strategy, the date and time settings for the currently selected
										activation strategy will be reset to defaults.
									</Alert>
									{activationStrategy === "time_of_day" ? (
										<TimesOfDay
											start_time={tableData ? tableData.activates_at : starttime}
											end_time={tableData ? tableData.deactivates_at : endtime}
											time={(starttime: any, endtime: any) => {
												setStarttime(new Date(starttime).toString());
												setEndtime(new Date(endtime).toString());
											}}
										/>
									) : activationStrategy === "days_of_week" ? (
										<DaysOfWeek
											data={days}
											days={(days: any, error: boolean) => {
												setDays(days);
												setDaysOfError(error);
											}}
										/>
									) : activationStrategy === "one_off" ? (
										<OneOff
											start_time={tableData ? tableData.activates_at : starttime}
											end_time={tableData ? tableData.deactivates_at : endtime}
											oneoff={(date: any, date2: any) => {
												setStarttime(new Date(date).toString());
												setEndtime(new Date(date2).toString());
											}}
										/>
									) : (
										<Alert
											variant="outlined"
											severity="warning"
											sx={{
												width: "100%",
												// padding: "1rem",
												margin: "1rem 0rem 1rem 0rem",
											}}
										>
											This will be a permanently active until you deactivate it
										</Alert>
									)}
								</Subcontainer>
								<TextContainer>
									<Heading>Descripton</Heading>
									<TextField
										label="Description"
										variant="outlined"
										multiline
										rows={3}
										fullWidth
										value={descripton}
										onChange={(e) => {
											setDescripton(e.target.value);
										}}
									/>
								</TextContainer>
							</>
						) : null}
					</MainContainer>
				</ContentContainer>
				<DialogActions
					sx={{
						padding: "1rem",
						gap: "1rem",
					}}
				>
					<Button
						autoFocus
						variant="outlined"
						onClick={() => {
							if (tableData) {
							} else {
								setConvertButton(false);
								setPriceButton(false);
							}
							onClose();
						}}
					>
						Cancel
					</Button>
					<Button
						autoFocus
						variant="contained"
						onClick={() => {
							submit();
						}}
						sx={{
							color: "#FFFFFF",
						}}
						disabled={
							basePriceError ||
							multiplierError ||
							(endtime && starttime && endtime <= starttime) ||
							locationTitle.length === 0 ||
							daysOfError
						}
					>
						{buttonText}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default CreateBoostModal;
