/* Common Imports */

import React from "react";

/* Redux Imports */

/* Component Imports */

import CloseIcon from "@mui/icons-material/Close";
import {
	Badge,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Link,
	styled,
	Typography,
} from "@mui/material";

/* Icon Imports */

import { NotificationsNoneOutlined } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import playNotificationSound from "../../lib/playNotificationSound";

const NotificationContent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const Description = styled(Typography)(({ theme }) => ({
	fontWeight: "400",
	fontSize: "1rem",
	lineHeight: "1rem",
	width: "100%",
}));

const NotificationContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.75rem",
	width: "100%",
	textDecoration: "none",
	"@media (pointer: fine)": {
		"&:hover": {
			backgroundColor: theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.04)" : "rgba(0, 0, 0, 0.04)",
		},
	},
	cursor: "pointer",
}));

const Notifications = ({ session, new_notification }: { session: any; new_notification: any }) => {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const [notifications, setNotifications] = React.useState<any>([]);
	const [notificationCount, setNotificationCount] = React.useState<number>(0);

	React.useEffect(() => {
		if (new_notification) {
			notifications.length > 0
				? setNotifications((prevNotifications: any) => [new_notification, ...prevNotifications])
				: setNotifications([new_notification]);
			setNotificationCount(notificationCount + 1);
			playNotificationSound();
		}
	}, [new_notification]);

	const handleClick = async (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setNotificationCount(0);
	};

	return (
		<React.Fragment>
			<IconButton
				color="primary"
				onClick={handleClick}
			>
				<Badge
					badgeContent={loading ? 0 : notificationCount >= 10 ? "9+" : notificationCount}
					color="primary"
					sx={{
						"& .MuiBadge-badge": {
							backgroundColor: "primary.main", // Change background color
							color: "#fff", // Change text color
						},
					}}
				>
					<NotificationsNoneOutlined sx={{ fontSize: "1.5rem" }} />
				</Badge>
			</IconButton>
			<Dialog
				open={Boolean(anchorEl)}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth={true}
				fullScreen={true}
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						color: "primary.main",
					}}
					component="div"
				>
					Notifications
					<IconButton
						aria-label="close"
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					{notifications.length > 0 ? (
						notifications.map((notification: any, index: number) => {
							return (
								<NotificationContainer
									key={index}
									sx={{
										padding: "0.75rem 1rem 0.75rem 1rem",
									}}
								>
									<NotificationContent
										sx={{
											padding: "0rem 0rem 0rem 0.5rem",
										}}
									>
										<Description
											variant="body1"
											color={notification.color}
										>
											{/* Bold multiple substrings between ** characters */}

											{notification.content.split("**").map((item: any, index: number) => {
												if (index % 2) {
													return <b key={index}>{item}</b>;
												} else {
													return <span key={index}>{item}</span>;
												}
											})}
										</Description>
										{notification.url ? (
											<Link
												sx={{
													padding: "0.5rem",
												}}
												href={notification.url}
												target="_blank"
											>
												<VisibilityIcon
													sx={{
														color: "primary.main",
														fontSize: "1rem",
													}}
												/>
											</Link>
										) : null}
									</NotificationContent>
								</NotificationContainer>
							);
						})
					) : (
						<Typography variant="body1">No new notifications</Typography>
					)}
				</DialogContent>
				<DialogActions></DialogActions>
			</Dialog>
		</React.Fragment>
	);
};
export default Notifications;
