import Dialog from "@mui/material/Dialog";
import React from "react";

import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	styled,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useRouter } from "next/router";
import UploadModal from "./BusinessProfileKYCDocsUploadModal";
import LoadingModal from "./LoadingModal";
import PDFViewModal from "./PDFViewModal";

const Image = styled("img")(({ theme }) => ({
	objectFit: "contain",
	width: "100%",
	height: "12.5rem",
}));

const ImgDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "1rem",
}));

const SubHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	lineHeight: "2rem",
	fontSize: "1rem",
	padding: "0rem 0rem 0.5rem 0rem",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	lineHeight: "1.2rem",
	fontSize: "1.125rem",
	padding: "0.5rem 0rem 1rem 1.5rem",
}));

const MainHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "1.125rem",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	padding: "1.5rem 0rem 1rem 0rem",
}));

const ImageContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "0rem 1rem 0rem 1rem",
}));

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "0.5rem",
	padding: "0.2rem",
}));

const ContentDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
}));

const UploadDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
}));

const Container = styled("div")(({ theme }) => ({
	// margin: "0.5rem 0rem 0rem 0rem",
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	gap: "0.25rem",
}));

const MessageBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
	padding: "1rem",
	wordSpacing: "0.5rem",
	[theme.breakpoints.only("sm")]: {
		width: "100%",
	},
}));

const MessageContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-end",
	// boxShadow: " 0px 1px 3px rgba(0, 0, 0, 0.12)",
	width: "100%",
	padding: "1rem",
	[theme.breakpoints.only("sm")]: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	},
}));

const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));

const KycApprovalModal = ({ open, onClose, data, user_id }: { open: any; onClose: any; data: any; user_id: any }) => {
	const router = React.useRef(useRouter()).current;

	const [message, setMessage] = React.useState<Array<string>>([]);
	const [currentMessage, setCurrentMessage] = React.useState("");
	const [OpenUploadModal, setOpenUplaodModal] = React.useState(false);
	const [openPDFModal, setOpenPDFModal] = React.useState(false);
	const [pdfUrl, setPdfUrl] = React.useState("");
	const [openModal, setOpenMoadal] = React.useState(false);

	const handleChangeMessage = (value: any) => {
		setCurrentMessage(value);
	};

	const add = (value: any) => {
		setMessage([...message, value]);
		setCurrentMessage("");
	};

	const submit = async () => {
		setOpenMoadal(true);

		await fetch(process.env.PRODUCTION_API_URL + "kyc-table/business/kyc-approval", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				userId: data.userId,
				status: value,
				reason: message,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					change: "true",
				},
			},
			undefined,
			{
				shallow: true,
			},
		);
		onClose();
		setMessage([]);
		setOpenMoadal(false);
	};

	const [identityDocument, setIdentityDocument] = React.useState<string>(
		data ? (data?.action ? data?.action?.selected_document : "") : "",
	);

	const [value, setValue] = React.useState(data ? data.status : "");

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue((event.target as HTMLInputElement).value);
	};

	React.useEffect(() => {
		if (data) {
			setValue(data.status ? data.status : "");
			if (data?.action) {
				if (data.action?.selected_document) {
					setIdentityDocument(data.action.selected_document);
				}
			}
		}
	}, [data]);

	const handleDelete = async (fileId: string, business_id: string) => {
		setOpenMoadal(true);

		const response = await fetch(process.env.PRODUCTION_API_URL + "kyc-table/business/delete-verified-documents", {
			method: "POST",
			body: JSON.stringify({
				userId: user_id,
				businessId: business_id,
				document_file_id: fileId,
			}),
		});
		const data = await response.json();
		setOpenMoadal(false);
	};

	let selectedDocsNumber: any = 0;

	switch (identityDocument) {
		case "Aadhar Card":
			selectedDocsNumber = data.action.aadhaar.aadhaar_number;
			break;
		case "Driving License":
			selectedDocsNumber = data.action.driving_license.driving_license_number;
			break;
		case "Voter ID":
			selectedDocsNumber = data.action.voter_id.voter_id_number;
			break;
		case "Passport":
			selectedDocsNumber = data.action.passport.passport_number;
			break;
		case "TIN Certificate":
			selectedDocsNumber = data.action.tin_certificate.tin_number;
			break;
		case "GST Certificate":
			selectedDocsNumber = data.action.gst_certificate.gst_number;
			break;
		case "License":
			selectedDocsNumber = data.action.license_from_legal_authority.license_number;
			break;
		case "MOA (Memorandum of Association)":
			selectedDocsNumber = data.action.memorandum_of_agreement.memorandum_of_agreement_number;
			break;
		case "RERA Certificate":
			selectedDocsNumber = data.action.rera_certificate.rera_number;
			break;
		case "Partnership Deed":
			selectedDocsNumber = data.action.partnership_deed.partnership_deed_number;
			break;
		case "Partnership Registration Certificate":
			selectedDocsNumber = data.action.partnership_registration_certificate.partnership_reg_certificate_number;
			break;
		case "AOA (Article of Association)":
			selectedDocsNumber = data.action.article_of_association.article_of_association_number;
			break;
		case "COI (Certificate of Incorporation)":
			selectedDocsNumber = data.action.certificate_of_incorporation.certificate_of_incorporation_number;
			break;
		case "LLP Agreement":
			selectedDocsNumber = data.action.llp_agreement.llp_agreement_number;
			break;
		default:
			break;
	}

	const columns: GridColDef[] = [
		{ field: "id", headerName: "ID", width: 50 },
		{
			field: "document_number",
			headerName: "Document Number",
			width: 140,
			editable: true,
		},
		{
			field: "document_name",
			headerName: "Document Name",
			width: 150,
			editable: true,
		},
		{
			field: "document_type",
			headerName: "Document Type",
			width: 150,
			editable: true,
		},
		{
			field: "document_file_id",
			headerName: "Document File ID",
			width: 160,
		},
		{
			field: "action",
			headerName: "Action",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<IconButton
							sx={{
								color: "#FC8019",
							}}
							onClick={() => {
								setPdfUrl(cellValues.row.document_file_id);

								setOpenPDFModal(true);
							}}
						>
							<VisibilityOutlinedIcon />
						</IconButton>
						<IconButton
							sx={{
								color: "#FF0000",
							}}
							onClick={() => {
								handleDelete(cellValues.row.document_file_id, data ? data.userId : "");
							}}
						>
							<DeleteForeverOutlinedIcon />
						</IconButton>
					</ActionActions>
				);
			},
		},
	];

	return (
		<Dialog
			open={Boolean(open)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			onClose={onClose}
			fullWidth={true}
			maxWidth="md"
			sx={{
				"& .MuiDialog-paper": {
					borderRadius: "1rem",
					boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
				},
			}}
		>
			<LoadingModal open={openModal} />

			<UploadModal
				open={OpenUploadModal}
				onClose={() => setOpenUplaodModal(false)}
				business_id={data ? data.userId : ""}
				user_id={user_id}
			/>
			<PDFViewModal
				open={openPDFModal}
				onClose={() => setOpenPDFModal(false)}
				pdfUrl={pdfUrl}
			/>
			<DialogTitle
				id="alert-dialog-title"
				sx={{}}
			>
				<MainHeading>KYC Approval</MainHeading>
				<Divider />
				<Close
					aria-label="close"
					onClick={() => {
						setMessage([]);
						onClose();
					}}
					sx={{
						position: "absolute",
						right: "0.5rem",
						top: "0.5rem",
						color: "#F76C35",
					}}
				>
					<CloseIcon />
				</Close>
			</DialogTitle>
			<DialogContent>
				<Heading>Pan card : {data?.action?.pan?.pan_number}</Heading>

				<ImageContainer>
					<ImgDiv>
						<ContentDiv>
							<SubHeading>Pan Front Side</SubHeading>
							<Image
								src={data?.action?.pan?.front_image_file_url?.regular}
								alt=""
							/>
						</ContentDiv>
					</ImgDiv>
				</ImageContainer>

				<Container>
					<Heading>Selected Document :</Heading>
					<Heading>{data?.action?.selected_document}</Heading>
					<Heading>KYC Type :</Heading>
					<Heading>{data ? data.kyc_type : ""}</Heading>
				</Container>
				<Container>
					<Heading>{data?.action?.selected_document}:</Heading>
					<Heading>{selectedDocsNumber}</Heading>
				</Container>

				{identityDocument === "Aadhar Card" ? (
					<ImageContainer>
						<ImgDiv>
							<ContentDiv>
								<SubHeading>Aadhar Front Side</SubHeading>
								<Image
									src={data?.action?.aadhaar?.front_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
							<ContentDiv>
								<SubHeading>Aadhar Back Side</SubHeading>
								<Image
									src={data?.action?.aadhaar?.back_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
						</ImgDiv>
					</ImageContainer>
				) : null}

				{identityDocument === "Driving License" ? (
					<ImageContainer>
						<ImgDiv>
							<ContentDiv>
								<SubHeading>DL Front Side</SubHeading>
								<Image
									src={data?.action?.driving_license?.front_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
							<ContentDiv>
								<SubHeading>DL Back Side</SubHeading>
								<Image
									src={data?.action?.driving_license?.back_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
						</ImgDiv>
					</ImageContainer>
				) : null}

				{identityDocument === "Voter ID" ? (
					<ImageContainer>
						<ImgDiv>
							<ContentDiv>
								<SubHeading>Voter Id Front Side</SubHeading>
								<Image
									src={data?.action?.voter_id?.front_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
							<ContentDiv>
								<SubHeading>Voter Id Back Side</SubHeading>
								<Image
									src={data?.action?.voter_id?.back_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
						</ImgDiv>
					</ImageContainer>
				) : null}

				{identityDocument === "Passport" ? (
					<ImageContainer>
						<ImgDiv>
							<ContentDiv>
								<SubHeading>Passport Front Side</SubHeading>
								<Image
									src={data?.action?.passport?.front_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
							<ContentDiv>
								<SubHeading>Passport Back Side</SubHeading>
								<Image
									src={data?.action?.passport?.front_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
						</ImgDiv>
					</ImageContainer>
				) : null}

				{identityDocument === "TIN Certificate" ? (
					<ImageContainer>
						<ImgDiv>
							<ContentDiv>
								<SubHeading>TIN Certificate Front Side</SubHeading>
								<Image
									src={data?.action?.tin_certificate?.front_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
						</ImgDiv>
					</ImageContainer>
				) : null}
				{identityDocument === "GST Certificate" ? (
					<ImageContainer>
						<ImgDiv>
							<ContentDiv>
								<SubHeading>GST Certificate Front Side</SubHeading>
								<Image
									src={data?.action?.gst_certificate?.front_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
						</ImgDiv>
					</ImageContainer>
				) : null}
				{identityDocument === "License" ? (
					<ImageContainer>
						<ImgDiv>
							<ContentDiv>
								<SubHeading>License Front Side</SubHeading>
								<Image
									src={data?.action?.license_from_legal_authority?.front_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
						</ImgDiv>
					</ImageContainer>
				) : null}
				{identityDocument === "MOA (Memorandum of Association)" ? (
					<ImageContainer>
						<ImgDiv>
							<ContentDiv>
								<SubHeading>MOA (Memorandum of Association) Front Side</SubHeading>
								<Image
									src={data?.action?.memorandum_of_agreement?.front_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
						</ImgDiv>
					</ImageContainer>
				) : null}
				{identityDocument === "RERA Certificate" ? (
					<ImageContainer>
						<ImgDiv>
							<ContentDiv>
								<SubHeading>RERA Certificate Front Side</SubHeading>
								<Image
									src={data?.action?.rera_certificate?.front_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
						</ImgDiv>
					</ImageContainer>
				) : null}
				{identityDocument === "Partnership Deed" ? (
					<ImageContainer>
						<ImgDiv>
							<ContentDiv>
								<SubHeading>Partnership Deed Front Side</SubHeading>
								<Image
									src={
										data?.action?.partnership_deed?.front_image_file_url?.regular ||
										data?.action?.partnership_deed?.front_image_file_url
									}
									alt=""
								/>
							</ContentDiv>
						</ImgDiv>
					</ImageContainer>
				) : null}
				{identityDocument === "Partnership Registration Certificate" ? (
					<ImageContainer>
						<ImgDiv>
							<ContentDiv>
								<SubHeading>Partnership Registration Certificate Front Side</SubHeading>
								<Image
									src={data?.action?.partnership_registration_certificate?.front_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
						</ImgDiv>
					</ImageContainer>
				) : null}
				{identityDocument === "AOA (Article of Association)" ? (
					<ImageContainer>
						<ImgDiv>
							<ContentDiv>
								<SubHeading>AOA (Article of Association) Front Side</SubHeading>
								<Image
									src={data?.action?.article_of_association?.front_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
						</ImgDiv>
					</ImageContainer>
				) : null}
				{identityDocument === "COI (Certificate of Incorporation)" ? (
					<ImageContainer>
						<ImgDiv>
							<ContentDiv>
								<SubHeading>COI (Certificate of Incorporation) Front Side</SubHeading>
								<Image
									src={data?.action?.certificate_of_incorporation?.front_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
						</ImgDiv>
					</ImageContainer>
				) : null}
				{identityDocument === "LLP Agreement" ? (
					<ImageContainer>
						<ImgDiv>
							<ContentDiv>
								<SubHeading>LLP Agreement Front Side</SubHeading>
								<Image
									src={data?.action?.llp_agreement?.front_image_file_url?.regular}
									alt=""
								/>
							</ContentDiv>
						</ImgDiv>
					</ImageContainer>
				) : null}
				<FormControl sx={{ padding: "1.5rem 0rem 0rem 1.5rem" }}>
					<FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
					<RadioGroup
						row
						aria-labelledby="demo-row-radio-buttons-group-label"
						name="row-radio-buttons-group"
						value={value}
						onChange={handleChange}
					>
						<FormControlLabel
							value="awaiting_documents"
							control={<Radio />}
							label="Awaiting Documents"
						/>
						<FormControlLabel
							value="kyc_pending"
							control={<Radio />}
							label="KYC Pending"
						/>
						<FormControlLabel
							value="kyc_completed"
							control={<Radio />}
							label="KYC Completed"
						/>
					</RadioGroup>
				</FormControl>

				{value === "kyc_completed" ? null : (
					<div>
						<TextContainer>
							<Typography
								variant="body1"
								sx={{
									color: "#FF5325",
									padding: "1rem 1.5rem 0rem 1.5rem",
								}}
							>
								Pending Documents
							</Typography>
						</TextContainer>
						{message.length > 0 ? (
							<List
								sx={{
									listStyleType: "disc",
									padding: "0rem 0rem 0rem 2rem",
								}}
							>
								{message.map((msg, index) => (
									<ListItem
										key={index}
										sx={{
											display: "list-item",
										}}
									>
										<ListItemText primary={msg} />
									</ListItem>
								))}
							</List>
						) : null}
						<MessageContainer>
							<MessageBoxContainer>
								<TextField
									variant="outlined"
									fullWidth
									size="small"
									value={currentMessage}
									placeholder="Enter your message here"
									onChange={(event: any) => {
										handleChangeMessage(event.target.value);
									}}
								/>
								<ViewOrUploadButton
									variant="outlined"
									color="primary"
									sx={{
										color: "#FC8019",
									}}
									disabled={currentMessage === ""}
									onClick={() => {
										add(currentMessage);
									}}
								>
									Add
								</ViewOrUploadButton>
							</MessageBoxContainer>
						</MessageContainer>
					</div>
				)}

				<div>
					<UploadDiv>
						<TextContainer>
							<Typography
								variant="body1"
								sx={{
									color: "#FF5325",
									padding: "1rem 1.5rem 0rem 1.5rem",
								}}
							>
								Internal use Documents
							</Typography>
						</TextContainer>
						<ViewOrUploadButton
							variant="contained"
							sx={{
								gap: "1rem",
							}}
							onClick={() => {
								setOpenUplaodModal(true);
							}}
						>
							Upload Verified Documents <FileUploadOutlinedIcon />
						</ViewOrUploadButton>
					</UploadDiv>
					<Box sx={{ height: "100%", padding: " 1rem", width: "100%" }}>
						<DataGridPremium
							rows={data?.action?.internal_use_documents.map((doc: any, index: number) => {
								return {
									id: index + 1,
									document_number: doc.document_number,
									document_name: doc.document_name,
									document_type: doc.document_type,
									document_file_id: doc.document_file_id,
								};
							})}
							columns={columns}
							hideFooter
							sx={{
								backgroundColor: "#FFFFFF",
								"& .MuiDataGrid-cell": {
									borderBottom: "none",
								},
							}}
						/>
					</Box>
				</div>
			</DialogContent>
			<DialogActions>
				<ViewOrUploadButton
					variant="outlined"
					sx={{
						color: "#FC8019",
					}}
					autoFocus
					onClick={() => {
						setMessage([]);
						onClose();
					}}
				>
					Cancel
				</ViewOrUploadButton>
				<ViewOrUploadButton
					variant="contained"
					onClick={() => {
						submit();
					}}
				>
					Save
				</ViewOrUploadButton>
			</DialogActions>
		</Dialog>
	);
};

export default KycApprovalModal;
