/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { Add, ArrowDropDown, NavigateNext } from "@mui/icons-material";
import {
	Box,
	Breadcrumbs,
	Button,
	Chip,
	debounce,
	Divider,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
	useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import LoadingModal from "../../../modal/LoadingModal";
import ReminderModal from "../../../modal/ReminderForUserModal";
import KycApprovalModal from "../../../modal/UserKycApprovalModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "untouched"
		? {
				backgroundColor: "rgba(244, 67, 54, 0.5)",
			}
		: id === "awaiting_documents"
			? {
					backgroundColor: "rgb(0,191,255)",
				}
			: id === "kyc_pending"
				? {
						backgroundColor: "rgba(255,0,10,0,1)",
					}
				: id === "kyc_completed"
					? {
							backgroundColor: "rgba(76, 175, 80, 0.5)",
						}
					: {
							backgroundColor: "rgba(255, 255, 255, 0.002)",
						},
);
const ChipDataForProfileType = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "user"
		? {
				backgroundColor: "#EEE3CB",
			}
		: id === "agent"
			? {
					backgroundColor: "#C1A3A3",
				}
			: id === "kyc_pending"
				? {
						backgroundColor: "rgba(255,0,10,0,1)",
					}
				: id === "kyc_completed"
					? {
							backgroundColor: "rgba(76, 175, 80, 0.5)",
						}
					: {
							backgroundColor: "rgba(255, 255, 255, 0.002)",
						},
);

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "2rem",
	width: "2rem",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));
const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const UserKycDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a local state for the modal

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	const [kycOpenModal, setKycOpenMoadal] = React.useState(false);

	const [kycdata, setKycData] = React.useState<any>([]);

	const [reasonModal, setReasonModal] = React.useState(false);

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount || 0);
		setDashboardData(DashboardData.DashboardData || []);
	}, [DashboardData]);

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "kyc-table/user/users-kyc-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define a function to handle the change in the select and update the status

  */

	const handleChange = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "kyc-table/user/kyc_status", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				status: event.target.value,
				userId: cellValues.row.userId,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					change: "true",
					page: paginationModel.page,
				},
			},
			undefined,
			{
				shallow: true,
			},
		);
		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to handle the change in the select and update the assigned to

  */

	const handleChangeAssigned = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "kyc-table/user/kyc-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				assigned_to: event.target.value,
				userId: cellValues.row.userId,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					change: "true",
					page: paginationModel.page,
				},
			},
			undefined,
			{
				shallow: true,
			},
		);
		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to delete the assigned to.

  */

	const deleteAssigned = async (item: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "kyc-table/user/kyc-delete-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
			},
			body: JSON.stringify({
				deletedAssignedTo: item,
				userId: cellValues.row.userId,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					change: "true",
					page: paginationModel.page,
				},
			},
			undefined,
			{
				shallow: true,
			},
		);
		setOpenMoadal(false);
	};

	/*

  & Next, let's define the data for the menu items

  */

	const menuItems = [
		{
			id: 1,
			label: "Untouched",
			value: "untouched",
		},
		{
			id: 2,
			label: "Awaiting Documents",
			value: "awaiting_documents",
		},
		{
			id: 3,
			label: "KYC Pending",
			value: "kyc_pending",
		},
		{
			id: 4,
			label: "KYC Completed",
			value: "kyc_completed",
		},
	];

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "username",
			headerName: "Username",
			width: 200,
		},
		{
			field: "userId",
			headerName: "User ID",
			width: 200,
		},

		{
			field: "phonenumber",
			headerName: "Phone Number",
			width: 150,
		},

		{
			field: "status",
			headerName: "Status",
			width: 150,
			editable: true,
			// type: "singleSelect",
			valueOptions: menuItems,
			valueFormatter: (params: any) => {
				if (!params) {
					return "untouched";
				}
				return params.value === "untouched"
					? "Untouched"
					: params.value === "awaiting_documents"
						? "Awaiting Documents"
						: params.value === "kyc_pending"
							? "KYC Pending"
							: params.value === "kyc_completed"
								? "KYC Completed"
								: "";
			},
			renderCell: (cellValues: any) => {
				const data = dashboardData.find((kyc: any) => kyc._id === cellValues.row.id);
				return (
					<ChipData
						id={data ? data.kyc_info?.kyc_status : cellValues.value}
						label={
							(data ? data.kyc_info?.kyc_status : cellValues.value) === "untouched"
								? "Untouched"
								: (data ? data.kyc_info?.kyc_status : cellValues.value) === "awaiting_documents"
									? "Awaiting Documents"
									: (data ? data.kyc_info?.kyc_status : cellValues.value) === "kyc_pending"
										? "KYC Pending"
										: (data ? data.kyc_info?.kyc_status : cellValues.value) === "kyc_completed"
											? "KYC Completed"
											: ""
						}
						variant="filled"
						onDelete={() => {}}
						deleteIcon={<ArrowDropDown />}
					/>
				);
			},
			renderEditCell: (cellValues: any) => {
				const data = dashboardData.find((property: any) => property._id === cellValues.row.id);
				return (
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						fullWidth={true}
						value={data ? data.kyc_info?.kyc_status : cellValues.value}
						onChange={(event: any) => handleChange(event, cellValues)}
					>
						{menuItems.map((item: any, index: number) => {
							return (
								<MenuItem
									value={item.value}
									key={index}
								>
									{item.label}
								</MenuItem>
							);
						})}
					</Select>
				);
			},
		},

		{
			field: "assigned",
			headerName: "Assigned",
			width: 300,
			editable: true,
			renderCell: (cellValues: any) => {
				return cellValues.value.length > 0 ? (
					<ActionActions>
						{cellValues.value.map((item: any, index: number) => {
							return (
								<Chip
									id={item}
									key={index}
									label={item.first_name + " " + item.last_name ? item.first_name + " " + item.last_name : "Assign to"}
									variant="outlined"
									onDelete={() => {
										deleteAssigned(item._id, cellValues);
									}}
									color="primary"
								/>
							);
						})}
					</ActionActions>
				) : (
					<Chip
						id="assign"
						label="Assign to"
						variant="outlined"
						onDelete={() => {}}
						deleteIcon={<Add />}
						color="primary"
					/>
				);
			},
			renderEditCell: (cellValues: any) => {
				return (
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						fullWidth={true}
						onChange={(event: any) => handleChangeAssigned(event, cellValues)}
						value={cellValues.value}
					>
						{DashboardData.list_of_moderators.map((item: any, index: number) => {
							return (
								<MenuItem
									value={item._id}
									key={index}
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
									}}
								>
									{item.first_name + " " + item.last_name}
								</MenuItem>
							);
						})}
					</Select>
				);
			},
		},
		{
			field: "action",
			headerName: "Action",
			width: 250,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<Link
							href={"tel:+91" + cellValues.row.phonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/call_blue.svg"
										: "/images/icons/social/call_blue.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Link
							href={"https://wa.me/+91" + cellValues.row.phonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/whatsapp_green.svg"
										: "/images/icons/social/whatsapp_green.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<div
							onClick={() => {
								kycFunction(cellValues.row);
							}}
						>
							<ImgTag
								src={theme.palette.mode === "dark" ? "/images/icons/social/edit.svg" : "/images/icons/social/edit.svg"}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</div>
						{cellValues?.row?.action?.kyc_status === "awaiting_documents" ? (
							<Button
								variant="contained"
								sx={{
									padding: "0.5rem 1rem",
									textTransform: "none",
									borderRadius: "1rem",
									fontSize: "0.75rem",
									background: "primary",
									color: "#fff",
								}}
								onClick={() => {
									setReasonModal(true);
									setKycData(cellValues.row);
								}}
							>
								remind
							</Button>
						) : null}
					</ActionActions>
				);
			},
		},
	];
	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	const handleClick = () => {};

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			KYC
		</Typography>,
	];

	const kycFunction = async (cellValues: any) => {
		setKycData(cellValues);
		setKycOpenMoadal(true);
	};
	return (
		<MainContainer>
			<LoadingModal open={openModal} />
			<ReminderModal
				open={reasonModal}
				userId={kycdata?.id}
				onClose={() => setReasonModal(false)}
				is_business={false}
			/>
			<KycApprovalModal
				open={kycOpenModal}
				data={kycdata}
				onClose={() => setKycOpenMoadal(false)}
				user_id={DashboardData.user_id}
			/>
			<HeadContainer>
				<HeadText>Know Your Customer (KYC)</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{DashboardData.accessLevel &&
			((DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("kyc")) ||
				DashboardData.accessLevel.includes("super_admin")) ? (
				DashboardData.AnalyticsData || DashboardData.ActivityLogData ? null : (
					<Box sx={{ height: "80vh", padding: " 1rem", width: "100%" }}>
						<DataGridPremium
							disableRowSelectionOnClick={false}
							slots={{ toolbar: GridToolbar }}
							rows={dashboardData?.map((item: any, key: number) => {
								return {
									id: item ? item._id : key,
									userId: item._id,
									profile_type: item.kyc_info?.kyc_profile_type,
									phonenumber: item.phone,
									username: item.first_name ? item.first_name + " " + item.last_name : "No Name",
									status: item.kyc_info?.kyc_status,
									assigned: item.kyc_info ? item.kyc_info.assigned_to : [],
									action: item.kyc_info,
								};
							})}
							columns={columns}
							pagination={true}
							paginationMode="server"
							onPaginationModelChange={(paginationModel) => {
								setPaginationModel(paginationModel);
							}}
							rowCount={rowCountState}
							pageSizeOptions={[100]}
							filterMode="server"
							onFilterModelChange={(newFilterModel) => {
								setQueryOptions(newFilterModel);
								FilteringFunction(newFilterModel);
							}}
							sx={{
								backgroundColor: "#FFFFFF",
								"& .MuiDataGrid-cell": {
									borderBottom: "none",
								},
							}}
						/>
					</Box>
				)
			) : (
				<YouDontHaveAccess>
					<Typography
						variant="h6"
						color="text.secondary"
					>
						You don&apos;t have access to this page
					</Typography>
				</YouDontHaveAccess>
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default UserKycDataTable;
