/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { NavigateNext } from "@mui/icons-material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { Box, Breadcrumbs, Chip, debounce, Divider, Link, Typography } from "@mui/material";
import { useRouter } from "next/router";
import ReasonModal from "../../../modal/ReasonModal";
/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "Business Profile"
		? {
				backgroundColor: "rgba(76, 175, 80, 0.5)",
			}
		: id === "User Profile"
			? {
					backgroundColor: "rgba(33, 150, 243, 0.5)",
				}
			: {
					backgroundColor: "rgba(255, 255, 255, 0.002)",
				},
);
const ChipDataForTransactionType = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "credit"
		? {
				backgroundColor: "rgba(76, 175, 80, 0.5)",
			}
		: id === "debit"
			? {
					backgroundColor: "rgba(244, 67, 54, 0.5)",
				}
			: {
					backgroundColor: "rgba(255, 255, 255, 0.002)",
				},
);

const ChipDataForStatus = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "success"
		? {
				backgroundColor: "rgba(76, 175, 80, 0.5)",
			}
		: id === "failed"
			? {
					backgroundColor: "rgba(244, 67, 54, 0.5)",
				}
			: {
					backgroundColor: "rgba(255, 255, 255, 0.002)",
				},
);

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const BeegruPointsTransactionsDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the modal

  */

	const [openMoadal, setOpenMoadal] = React.useState(false);

	const [purpose, setPurpose] = React.useState<string>("");

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount || 0);
		setDashboardData(DashboardData.DashboardData || []);
	}, [DashboardData]);

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(
				process.env.PRODUCTION_API_URL + "beegru-points-transaction-table/beegru-points-transaction-filtering",
				{
					method: "POST",
					cache: "no-store",
					headers: {
						"Content-Type": "text/plain",
						"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
					},
					body: JSON.stringify({
						...router.query,
						email: session ? (session.user ? session.user.email : "") : "",
						filter: newFilterModel,
						page: paginationModel.page,
					}),
				},
			);

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "_id",
			headerName: "ID",
			width: 150,
		},
		{
			field: "creator_type",
			headerName: "Creator Type",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<ChipData
						id={cellValues.value}
						label={cellValues.value}
						variant="outlined"
					/>
				);
			},
		},
		{
			field: "username",
			headerName: "Username",
			width: 200,
		},
		{
			field: "transaction_type",
			headerName: "Transaction Type",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<ChipDataForTransactionType
						id={cellValues.value}
						label={cellValues.value}
						variant="outlined"
					/>
				);
			},
		},
		{
			field: "transaction_amount_in_beegru_points",
			headerName: "Transaction Amount in Beegru Points",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<>
						{cellValues && cellValues.row.transaction_type === "debit" ? (
							<Typography
								variant="body1"
								color="text.primary"
								sx={{ color: "#F44336" }}
							>
								-{cellValues.value}
							</Typography>
						) : (
							<Typography
								variant="body1"
								color="text.primary"
								sx={{ color: "#4CAF50" }}
							>
								+{cellValues.value}
							</Typography>
						)}
					</>
				);
			},
		},
		{
			field: "transaction_status",
			headerName: "Transaction Status",
			width: 160,
			renderCell: (cellValues: any) => {
				return (
					<ChipDataForStatus
						id={cellValues.value}
						label={cellValues.value}
						variant="outlined"
					/>
				);
			},
		},

		{
			field: "entity_type",
			headerName: "Entity Type",
			width: 150,
		},
		{
			field: "entity",
			headerName: "Entity",
			width: 150,
		},
		{
			field: "created_at",
			headerName: "Created At",
			width: 150,
			filterable: false,
		},
		{
			field: "transaction_purpose",
			headerName: "Transaction Purpose",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<Link
						onClick={() => {
							setOpenMoadal(true);
							setPurpose(cellValues.value);
						}}
						sx={{
							textDecoration: "none",
							cursor: "pointer",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "rewards",
			headerName: "Rewards",
			width: 150,
			renderCell: (cellValues: any) => {
				if (cellValues.value) {
					return <EmojiEventsIcon color="primary" />;
				}
				return <Typography variant="body1">--</Typography>;
			},
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			Beegru Points Transactions
		</Typography>,
	];

	return (
		<MainContainer>
			<ReasonModal
				open={openMoadal}
				onClose={() => setOpenMoadal(false)}
				purpose={purpose}
			/>
			<HeadContainer>
				<HeadText> Beegru Points Transactions</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{DashboardData.accessLevel &&
			((DashboardData.accessLevel.includes("admin") &&
				DashboardData.accessLevel.includes("beegru_points_transaction")) ||
				DashboardData.accessLevel.includes("super_admin")) ? (
				DashboardData.AnalyticsData || DashboardData.ActivityLogData ? null : (
					<Box sx={{ height: "80vh", padding: " 1rem", width: "100%" }}>
						<DataGridPremium
							disableRowSelectionOnClick={false}
							slots={{ toolbar: GridToolbar }}
							rows={dashboardData?.map((item: any, key: number) => {
								return {
									id: item ? item._id : key,
									_id: item ? item._id : "",
									creator_type: item
										? item.transaction
											? item.transaction.created_by
												? item.transaction.created_by.is_business_profile
													? "Business Profile"
													: "User Profile"
												: ""
											: ""
										: "",
									username: item
										? item.transaction
											? item.transaction.created_by
												? item.transaction.created_by.is_business_profile
													? item.transaction.created_by.business_profile_id?.title
													: item.transaction.created_by.user_id
														? item.transaction.created_by.user_id.first_name +
															" " +
															item.transaction.created_by.user_id.last_name
														: "--"
												: "-ff-"
											: "--"
										: "--",
									transaction_type: item ? (item.transaction ? item.transaction.transaction_type : "") : "",
									transaction_amount_in_beegru_points: item
										? item.transaction
											? item.transaction.transaction_amount_in_beegru_points
											: ""
										: "",
									transaction_status: item ? (item.transaction ? item.transaction.transaction_status : "") : "",
									transaction_purpose: item ? (item.transaction ? item.transaction.transaction_purpose : "") : "",
									entity_type: item
										? item.transaction
											? item.transaction.transaction_related_entity?.entity_type
											: ""
										: "",
									entity: item ? (item.transaction ? item.transaction.transaction_related_entity?.entity_id : "") : "",
									created_at: item.created_at,
									rewards: item
										? item.transaction
											? item.transaction.transaction_purpose
												? item.transaction.transaction_purpose.includes("reward")
												: false
											: false
										: false,
								};
							})}
							getRowId={(row) => row.id}
							columns={columns}
							pagination={true}
							paginationMode="server"
							onPaginationModelChange={(paginationModel) => {
								setPaginationModel(paginationModel);
							}}
							rowCount={rowCountState}
							pageSizeOptions={[100]}
							filterMode="server"
							onFilterModelChange={(newFilterModel) => {
								setQueryOptions(newFilterModel);
								FilteringFunction(newFilterModel);
							}}
							sx={{
								backgroundColor: "#FFFFFF",
								"& > .MuiDataGrid-columnSeparator": {
									display: "none",
								},
								"& .MuiDataGrid-cell": {
									borderBottom: "none",
								},
							}}
						/>
					</Box>
				)
			) : (
				<YouDontHaveAccess>
					<Typography
						variant="h6"
						color="text.secondary"
					>
						You don&apos;t have access to this page
					</Typography>
				</YouDontHaveAccess>
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default BeegruPointsTransactionsDataTable;
