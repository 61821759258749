import Dialog from "@mui/material/Dialog";
import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
	Button,
	DialogContent,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	ListItemText,
	TextField,
	Typography,
	styled,
} from "@mui/material";

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	
	width: "100%",
}));

const MessageBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	padding: "1rem",
	wordSpacing: "0.5rem",
	gap: "1rem",
	[theme.breakpoints.only("sm")]: {
		width: "100%",
	},
}));

const MessageContainer = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-end",
	width: "100%",
	borderRadius: "8px",
	padding: "1rem",
}));

const UpdatePropertyReminderModal = ({
	open,
	onClose,
	propertyId,
	transactiontype,
}: {
	open: any;
	onClose: any;
	propertyId: any;
	transactiontype: string;
}) => {
	const [message, setMessage] = React.useState<Array<string>>([]);
	const [currentMessage, setCurrentMessage] = React.useState("");

	const handleChangeMessage = (value: any) => {
		setCurrentMessage(value);
	};

	const add = (value: any) => {
		setMessage([...message, value]);
		setCurrentMessage("");
	};
	const submitMessage = async () => {
		await fetch(process.env.PRODUCTION_API_URL + "property-table/send_a_reminder_mail_to_update_property", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				propertyId: propertyId ? propertyId : "",
				message: message,
				transactiontype: transactiontype,
			}),
		});
		setMessage([]);
	};

	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle id="alert-dialog-title">
					<Typography
						variant="h6"
						component="div"
					>
						Send a reminder mail to the user to complete the task
					</Typography>
					<Close
						aria-label="close"
						onClick={() => {
							setMessage([]);
							onClose();
						}}
						sx={{
							position: "absolute",
							right: "0.5rem",
							top: "0.5rem",
							color: "#F76C35",
						}}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<Container>
					{message.length > 0 ? (
						<List
							sx={{
								listStyleType: "disc",
								pl: 2,
							}}
						>
							{message.map((msg, index) => (
								<ListItem
									key={index}
									sx={{
										display: "list-item",
									}}
								>
									<ListItemText primary={msg} />
								</ListItem>
							))}
						</List>
					) : null}
					<MessageContainer>
						<MessageBoxContainer>
							<TextField
								variant="outlined"
								fullWidth
								size={"small"}
								value={currentMessage}
								placeholder="Enter your message here"
								onChange={(event: any) => {
									handleChangeMessage(event.target.value);
								}}
							/>
							<Button
								variant="outlined"
								color="primary"
								onClick={() => {
									add(currentMessage);
								}}
							>
								Add
							</Button>
						</MessageBoxContainer>
						<Button
							disabled={message.length === 0}
							variant="contained"
							sx={{
								backgroundColor: "primary",
								color: "#ffffff",
							}}
							onClick={() => {
								submitMessage();
								onClose();
							}}
						>
							Send
						</Button>
					</MessageContainer>
				</Container>
			</Dialog>
		</div>
	);
};

export default UpdatePropertyReminderModal;
