import Dialog from "@mui/material/Dialog";
import React from "react";

import {
  Button,
  DialogActions,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";

import { ObjectId } from "mongodb";
import { useRouter } from "next/router";

const SubHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  lineHeight: "2rem",
  fontSize: "1rem",
  padding: "0rem 0rem 0.5rem 0rem",
}));

const MainHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1.125rem",
  padding: "1.5rem 1.5rem 1rem 1.5rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  color: "#F46C34",
}));

const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
  padding: "1.5rem 2rem 1.5rem 2rem",
  gap: "1rem",
}));

const BannnedUsersModal = ({
  open,
  onClose,
  userId,
  session,
  username,
}: {
  open: any;
  onClose: any;
  userId: string | ObjectId;
  session: any;
  username: string;
}) => {
  /*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

  const router = React.useRef(useRouter()).current;

  /*

  & Next, let's define a local state for the component. Here we have the following:

  & 1. Beegrupoints - a number that determines the number of beegru points to be added or removed (default: 0)

  & 2. reason - a string that determines the reason for adding or removing beegru points (default: "")

  */

  const [value, setValue] = React.useState("1");

  const [reason, setReason] = React.useState("");

  /*

  & Next, let's define a function that will be called when the user clicks on the save button. This function will make a POST request to the /api/user-table/update-beegru-points endpoint to update the beegru points of the user.

  */

  const submit = async () => {
    await fetch(
      process.env.PRODUCTION_API_URL + "user-table/update-banned-users",
      {
        method: "POST",
        cache: "no-store",
        headers: {
          "Content-Type": "text/plain",
          "x-vercel-protection-bypass":
            process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
        },
        body: JSON.stringify({
          userId: userId,
          reason: reason,
          banned: value,
          session: session,
        }),
      }
    );
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          change: "true",
        },
      },
      undefined,
      {
        shallow: true,
      }
    );
    onClose();

    setReason("");
  };

  return (
    <div>
      <Dialog
        open={Boolean(open)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={onClose}
        fullWidth={true}
        maxWidth="md"
      >
        <MainHeading>Ban or Unban {username}</MainHeading>
        <ImageContainer>
          <SubHeading>Ban or Unban :</SubHeading>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="addOrRemove"
              name="row-radio-buttons-group"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            >
              <FormControlLabel value="1" control={<Radio />} label="Ban" />
              <FormControlLabel value="0" control={<Radio />} label="Unban" />
            </RadioGroup>
          </FormControl>
          <SubHeading>Reason :</SubHeading>
          <textarea
            rows={4}
            cols={50}
            style={{ width: "100%" }}
            placeholder="reason....."
            onChange={(e) => setReason(e.target.value)}
          />
        </ImageContainer>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={submit}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BannnedUsersModal;
