import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import { DialogContent, DialogContentText, DialogTitle, IconButton, styled } from "@mui/material";

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));

const Container = styled("div")(({ theme }) => ({
	position: "absolute",
	zIndex: 1,
	top: 0,
	left: 0,
	height: "2rem",
	width: "100%",
}));
const MainContainer = styled(DialogContentText)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	whiteSpace: "pre-wrap",
}));

const ErrorMessageModal = ({ open, onClose, errorMessage }: { open: any; onClose: any; errorMessage: any }) => {
	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle id="alert-dialog-title">Error Message</DialogTitle>
				<Container>
					<Close
						aria-label="close"
						onClick={onClose}
						sx={{
							position: "absolute",
							right: "0.5rem",
							top: "0.5rem",
							color: "#F76C35",
						}}
					>
						<CloseIcon />
					</Close>
				</Container>
				<DialogContent
					sx={{
						wordBreak: "break-word",
					}}
				>
					{errorMessage}
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ErrorMessageModal;
